import React from 'react';
import { connect } from 'react-redux';

import Form from 'react-mui-lib/form-widgets/Form';
import TextField from 'react-mui-lib/form-widgets/TextField';
import Checkbox from 'react-mui-lib/form-widgets/Checkbox';
import Select from 'react-mui-lib/form-widgets/Select';
import CheckboxList from 'react-mui-lib/form-widgets/CheckboxList';
import Text from 'react-mui-lib/form-widgets/Text';
import registry from 'react-mui-lib/registry';

import DeleteButton from '../locallib/DeleteButton';
import { has_superaccess } from '../utils';


class UserForm extends React.Component {
    constructor(props) {
        super(props);
        this.controller = registry.get('user_form_controller');
        this.franchise_list_controller = registry.get('franchise_list_controller');
        this.role_list_controller = registry.get('role_list_controller');
    }

    render() {
        const c = this;

        return <Form controller={c.controller}
                     title_new="Новый пользователь"
                     title_edit="Пользователь"
                     actions_edit={[
                        <span style={{flexGrow: 1}} />,
                        <DeleteButton dialogTitle="Удалить пользователя?" />
                    ]}>

            <div>
                <TextField path="login"
                           label="Имя пользователя" />
            </div>
            {has_superaccess() &&
                <div>
                    <Checkbox path="is_enabled"
                              label="Активен (вход разрешен)" />
                </div>
            }
            {has_superaccess() &&
                <div>
                    <CheckboxList path="roles"
                                  options={c.role_list_controller.getAsOptions()}
                                  label="Роли" />
                </div>
            }
            <div>
                <TextField path="name"
                           label="Имя" />
            </div>
            {has_superaccess() &&
                <div>
                    <TextField path="comment"
                               label="Комментарий"
                               multiLine={true} />
                </div>
            }
            {has_superaccess() &&
                <div>
                    <Select path="franchise_id"
                            options={c.franchise_list_controller.getAsOptions()}
                            label="Франшиза" />
                </div>
            }
            {c.controller.state.entity_id &&
                <div>
                    <div>
                        Зарегистрирован: <Text path="registered"/>
                    </div>
                    <div>
                        Последний вход: <Text path="last_login" />
                    </div>
                    <div>
                        Последняя активность: <Text path="last_activity" />
                    </div>
                </div>
            }
            <div>
                <TextField path="new_password"
                           type="password"
                           autoComplete="new-password"
                           label="Новый пароль" />
            </div>
        </Form>;
    }

    init(props) {
        const c = this;
        const entity_id = props.match.params.id;

        if (entity_id === undefined) {
            c.controller.editNew();
        } else {
            c.controller.editExisting(entity_id);
        }
    }

    componentWillMount() {
        this.init(this.props);
    }

    componentWillReceiveProps(new_props) {
        const c = this;

        if (new_props.location.pathname !== c.props.location.pathname) {
            c.init(new_props);
        }
    }
}

export default connect(
    /* mapStateToProps */ state => ({
        state__: state
    }),
    /* mapDispatchToProps */ state => ({
    })
)(UserForm);
