import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import Icon from  '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import registry from 'react-mui-lib/registry';


class WinnerList extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('participant_grid_controller');
    }

    render() {
        const c = this;

        const { winners } = c.props;

        if (winners.length === 0) {
            return <p>Не выбрано ни одного победителя</p>;
        }

        return <table style={{width: '100%'}}>
            {winners.map((w, i) =>
                <tr id={w.app_user_id}>
                    <td>{w.winner_order}</td>
                    <td>{w.app_user_id}</td>
                    <td>{w.nickname}</td>
                    <td>
                        <Tooltip title="Вверх">
                            <IconButton disabled={i === 0}
                                        onClick={() => c.controller.moveWinnerUp(w)}>
                                <Icon>arrow_upward</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Вниз">
                            <IconButton disabled={i === winners.length - 1}
                                        onClick={() => c.controller.moveWinnerDown(w)}>
                                <Icon>arrow_downward</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Удалить">
                            <IconButton onClick={() => c.controller.setWinner(w, false)}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </Tooltip>
                    </td>
                </tr>
            )}
        </table>;
    }
}


export default class WinnerListDialog extends React.Component {
    open() {
        const c = this;

        c.setState({
            dialog_open: true
        });
    }

    constructor(props) {
        super(props);

        this.controller = registry.get('participant_grid_controller');

        this.state = {
            dialog_open: false
        };
    }

    render() {
        const c = this;

        const winners = c.controller.getWinners();

        return <Dialog fullWidth={true}
                       open={c.state.dialog_open}
                       onClose={() => c.setState({dialog_open: false})}>
            <DialogTitle>
                Победители игры
            </DialogTitle>
            <DialogContent>
                <WinnerList winners={winners} />
            </DialogContent>
            <DialogActions>
                <Button variant="raised"
                        color="primary"
                        disabled={winners.length === 0}
                        onClick={() => c.saveWinners()}>
                    Сохранить
                </Button>
                <Button color="primary"
                        onClick={() => c.setState({dialog_open: false})} >
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>;
    }

    saveWinners() {
        const c = this;

        c.controller.saveWinners().then(() => {
            c.setState({
                dialog_open: false
            });
        });
    }
}
