import React from 'react';
import { connect } from 'react-redux';

import Form from 'react-mui-lib/form-widgets/Form';
import TextField from 'react-mui-lib/form-widgets/TextField';
import Select from 'react-mui-lib/form-widgets/Select';
import registry from "react-mui-lib/registry";

import DeleteButton from '../locallib/DeleteButton';


class BeaconForm extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('beacon_form_controller');
        this.franchise_list_controller = registry.get('franchise_list_controller');
    }

    render() {
        const c = this;

        return <Form controller={c.controller}
                     title_new="Новый маяк"
                     title_edit="Маяк"
                     actions_edit={[
                        <span style={{flexGrow: 1}} />,
                        <DeleteButton dialogTitle="Удалить маяк?" />
                    ]}>

            <div>
                <Select path="franchise_id"
                        options={c.franchise_list_controller.getAsOptions()}
                        label="Франшиза" />
            </div>
            <div>
                <TextField path="ibeacon_uuid"
                           label="iBeacon UUID" />
            </div>
            <div>
                <TextField path="ibeacon_major"
                           label="iBeacon Major"
                           multiLine={true} />
            </div>
            <div>
                <TextField path="ibeacon_minor"
                           label="iBeacon Minor" />
            </div>
            <div>
                <TextField path="description"
                           label="Место установки" />
            </div>
        </Form>;
    }

    init(props) {
        const c = this;
        const entity_id = props.match.params.id;

        if (entity_id === undefined) {
            c.controller.editNew();
        } else {
            c.controller.editExisting(entity_id);
        }
    }

    componentWillMount() {
        this.init(this.props);
    }

    componentWillReceiveProps(new_props) {
        const c = this;

        if (new_props.location.pathname !== c.props.location.pathname) {
            c.init(new_props);
        }
    }
}

export default connect(
    /* mapStateToProps */ state => ({
        state__: state
    }),
    /* mapDispatchToProps */ state => ({
    })
)(BeaconForm);
