import React from 'react';

import Grid from 'react-mui-lib/grid-widgets/Grid';
import EditButton from "react-mui-lib/grid-widgets/EditButton";
import registry from "react-mui-lib/registry";
import { format_timestamp } from '../utils';


const columns = [{
    key: 'login',
    label: 'Логин',
    template: ({val, row}) =>
        <div style={{whiteSpace: 'nowrap'}}>
            <EditButton to={`/admin/user/${row.id}`} />
            {val}
        </div>
}, {
    key: 'is_enabled',
    label: 'Статус'
}, {
    key: 'name',
    label: 'Имя'
}, {
    key: 'franchise.name',
    label: 'Франшиза'
}, {
    key: 'registered',
    label: 'Регистрация',
    template: ({val, row}) =>
        <span>{format_timestamp(val)}</span>
}, {
    key: 'last_activity',
    label: 'Посл. активность',
    template: ({val, row}) =>
        <span>{format_timestamp(val)}</span>
}];


class UserGrid extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('user_grid_controller');
    }

    render() {
        const c = this;

        return <Grid controller={c.controller}
                     columns={columns}
                     title="Пользователи админки"/>;
    }

    componentWillMount() {
        const c = this;
        c.controller.loadIfNeeded();
    }
}

export default UserGrid;
