import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiTextField from '@material-ui/core/TextField';
import MuiSwitch from '@material-ui/core/Switch';


export default class NotificationDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: props.title,
            message: props.message,
            play_sound: false
        };
    }

    render() {
        const c = this;
        const { dialogTitle, open, onSubmit, onClose } = c.props;

        const switch_obj =
            <MuiSwitch checked={c.state.play_sound}
                       onChange={(e) => c.setState({play_sound: e.target.checked})} />;

        return <Dialog fullWidth={true}
                       open={open}
                       onClose={onClose}>
            <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                        {/*Введите сообщение*/}
                    {/*</DialogContentText>*/}
                    <FormControl margin='normal'
                                 fullWidth>
                        <MuiTextField value={c.state.title}
                                      onChange={(e) => c.setState({title: e.target.value})}
                                      autoFocus
                                      label="Заголовок"
                                      fullWidth />
                    </FormControl>
                    <FormControl margin='normal'
                                 fullWidth>
                        <MuiTextField value={c.state.message}
                                      onChange={(e) => c.setState({message: e.target.value})}
                                      autoFocus
                                      label="Сообщение"
                                      fullWidth
                                      multiline />
                    </FormControl>
                    <FormControl margin='normal'
                                 fullWidth>
                        <FormControlLabel control={switch_obj}
                                          label="Звук"/>
                    </FormControl>
                </DialogContent>
            <DialogActions>
                <Button variant="raised"
                        color="primary"
                        onClick={onSubmit.bind(null, c.state)}>
                    Отправить
                </Button>
                <Button color="primary"
                        onClick={onClose} >
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>;
    }

    componentWillReceiveProps(props) {
        Object.assign(this.state, {
            title: props.title,
            message: props.message
        });
    }
}
