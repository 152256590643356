import registry from 'react-mui-lib/registry';

import local_controller from './misc/local';
import game_grid_controller from './game/game-grid-controller';
import game_form_controller from './game/game-form-controller';
import game_notifications_form_controller from './game/game-notification-form-controller';
import round_selector_controller from './game/round-selector-controller';
import round_form_controller from './game/round-form-controller';
import participant_grid_controller from './game/participants/participant-grid-controller';
import participant_form_controller from './game/participants/participant-form-controller';
import beacon_form_controller from './beacon/beacon-form-controller';
import appuser_grid_controller from './appuser/appuser-grid-controller';
import appuser_form_controller from './appuser/appuser-form-controller';
import promo_grid_controller from './promo/promo-grid-controller';
import promo_form_controller from './promo/promo-form-controller';
import user_grid_controller from './user/user-grid-controller';
import user_form_controller from './user/user-form-controller';
import franchise_form_controller from './franchise/franchise-form-controller'
import region_grid_controller from './region/region-grid-controller';
import region_form_controller from './region/region-form-controller';
import locality_form_controller from './region/locality-form-controller';

import MessageController from 'react-mui-lib/messages/controller';
import RestClient from 'react-mui-lib/network/rest-client';


registry.register('local_controller', local_controller);
registry.register('game_grid_controller', game_grid_controller);
registry.register('game_form_controller', game_form_controller);
registry.register('game_notifications_form_controller', game_notifications_form_controller);
registry.register('round_selector_controller', round_selector_controller);
registry.register('round_form_controller', round_form_controller);
registry.register('participant_grid_controller', participant_grid_controller);
registry.register('participant_form_controller', participant_form_controller);
registry.register('beacon_form_controller', beacon_form_controller);
registry.register('appuser_grid_controller', appuser_grid_controller);
registry.register('appuser_form_controller', appuser_form_controller);
registry.register('promo_grid_controller', promo_grid_controller);
registry.register('promo_form_controller', promo_form_controller);
registry.register('user_grid_controller', user_grid_controller);
registry.register('user_form_controller', user_form_controller);
registry.register('franchise_form_controller', franchise_form_controller);
registry.register('region_grid_controller', region_grid_controller);
registry.register('region_form_controller', region_form_controller);
registry.register('locality_form_controller', locality_form_controller);

registry.register('rest_client', new RestClient({
    url_prefix: '/admin/rest',
    csrf_token: window.Params === undefined ? null : window.Params.csrf_token
}));
registry.register('message_controller', new MessageController());
