/*
    global ymaps: false
    global Params: false
*/

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';

import { errors } from 'react-mui-lib/form-widgets/errors';


const INITIAL_MAP_CENTER = [55.747130, 37.632447];
const INITIAL_ZOOM = 12;
const DEFAULT_RADIUS = 25;
const MIN_RADIUS = 15;
const MAX_RADIUS = 1000;


const styles = {
    map: {
        width: '100%',
        height: '300px'
    },
    hidden: {
        display: 'none'
    }
};


class GeoField extends React.Component {
    render() {
        const c = this;
        const {
            label,
            width = 'full',
            classes  // injected by withStyles()
        } = c.props;
        const controller = c.context.controller;
        const model = c.getModel();

        const editable = controller.isEditable() && !model.is('readonly');
        const valid = model.isValid() || (!model.isDirty() && !controller.state.submitted);

        c.updateMap();

        return <FormControl disabled={!editable}
                            error={!valid}
                            fullWidth
                            className={classNames({
                                [classes.hidden]: model.is('hidden')
                            })}>
            <FormLabel component="legend">
                {label}
            </FormLabel>
            <div ref="map"
                 className={classes.map}/>
            {valid ? null : errors(model)}
        </FormControl>;
    }

    componentDidMount() {
        const c = this;
        const value = c.getModel().getModelValue();

        c.initMap(value.coords);  // [] or undefined
    }

    componentWillUnmount() {
        const c = this;

    }

    initMap(map_center) {
        const c = this;
        const { path } = c.props;

        ymaps.ready(() => {
            const Params = window.Params || {};  // for webpack server where Params are not available
            const default_map = Params.franchise === undefined ? {
                center: INITIAL_MAP_CENTER,
                zoom: INITIAL_ZOOM
            } : Params.franchise.default_map;

            c.map = new ymaps.Map(c.refs.map, {
                center: map_center || default_map.center,
                zoom: default_map.zoom,
                controls: ['zoomControl', 'typeSelector'], //, 'fullscreenControl'],
                behaviors: ['default', 'scrollZoom']
            }, {
                yandexMapDisablePoiInteractivity: true,
                suppressMapOpenBlock: true,
                nativeFullscreen: true
            });

            const fullscreenControl = new ymaps.control.FullscreenControl();

            // workaround: remove wrong width set by yandex maps when map exits full screen mode
            fullscreenControl.events.add('fullscreenexit', () => {
                //c.map.container.fitToViewport();
                const el1 = c.refs.map.childNodes[0];
                el1.style.width = null;
                const el2 = el1.childNodes[0];
                el2.style.width = null;
            });
            c.map.controls.add(fullscreenControl);

            c.map.events.add('click', e => {
                const newCoords = e.get('coords');
                const value = c.getValue();
                const radius = _.isEmpty(value) ? DEFAULT_RADIUS : value.radius;

                c.setValue({
                    coords: newCoords,
                    radius
                });
            });

            c.updateMap();
        });
    }

    updateMap() {
        const c = this;

        if (!c.map) return;

        const value = c.getValue();

        if (!_.isEmpty(value)) {
            if (c.map.geoObjects.getLength() === 0) {
                const { endPoint } = ymaps.coordSystem.geo.solveDirectProblem(
                    value.coords, [0, 1], value.radius);

                const circle = new ymaps.Circle([
                    value.coords,
                    value.radius
                ], {
                    balloonContent: "", //"Radius of the circle: 10 km",
                    hintContent: "Move me"
                }, {
                    draggable: true,
                    fillColor: "#DB709377",
                    strokeColor: "#990066",
                    strokeOpacity: 0.8,
                    strokeWidth: 5
                });
    
                const mark = new ymaps.Placemark(endPoint, {
                    //
                }, {
                    draggable: true,
                });

                circle.events.add("drag", e => {
                    const { endPoint } = ymaps.coordSystem.geo.solveDirectProblem(
                        circle.geometry.getCoordinates(),
                        [0, 1],
                        circle.geometry.getRadius()
                    );

                    mark.geometry.setCoordinates(endPoint);    
                });

                circle.events.add("dragend", e => {
                    // TODO ! if (!c.isEditable) return;

                    const value = c.getValue();
                    const newCoords = circle.geometry.getCoordinates();

                    c.setValue({
                        coords: newCoords,
                        radius: value.radius
                    });
                });

                const get_distance = mark => {
                    const value = c.getValue();
                    const endPoint = mark.geometry.getCoordinates();
                    const { distance } = ymaps.coordSystem.geo.solveInverseProblem(
                        value.coords, endPoint
                    );

                    return Math.min(Math.max(distance, MIN_RADIUS), MAX_RADIUS);
                };

                mark.events.add("drag", e => {
                    circle.geometry.setRadius(get_distance(mark));
                });

                mark.events.add("dragend", e => {
                    const value = c.getValue();

                    c.setValue({
                        coords: value.coords,
                        radius: get_distance(mark)
                    });
                });

                c.map.geoObjects.add(circle);
                c.map.geoObjects.add(mark);
            } else {
                const circle = c.map.geoObjects.get(0);
                const mark = c.map.geoObjects.get(1);

                circle.geometry.setCoordinates(value.coords);
                circle.geometry.setRadius(value.radius);

                const { endPoint } = ymaps.coordSystem.geo.solveDirectProblem(
                    value.coords, [0, 1], value.radius);
                mark.geometry.setCoordinates(endPoint);
            }
        } else if (c.map.geoObjects.getLength() > 0) {
            c.map.geoObjects.removeAll();
        }
    }

    getValue() {
        const c = this;

        return c.getModel().getViewValue();
    }

    setValue(value) {
        const c = this;
        const { path } = c.props;
        const { controller } = c.context;

        controller.valueChanged(path, value);
    }

    getModel() {
        const c = this;
        const { path } = c.props;
        const { controller } = c.context;

        return controller.getModel(path);      
    }
}

GeoField.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.string
};

GeoField.contextTypes = {
    controller: PropTypes.object
};

export default withStyles(styles)(GeoField);
