import React from "react";
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";

import RoundSelector from "./RoundSelector";
import GameForm from "./GameForm";
import RoundForm from "./RoundForm";
import ParticipantGrid from "./participants/ParticipantGrid";
import PreviewPopup from "./preview/PreviewPopup";
import registry from "react-mui-lib/registry";


class GameEditor extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('game_form_controller');

        this.previewRef = React.createRef();
    }

    render() {
        const c = this;
        const {
            id: game_id
        } = c.props.match.params;

        return <div>
            <RoundSelector game_id={game_id}
                           openPreview={() => c.previewRef.current.open()} />

            <Switch>
            <Route exact path="/admin/game/:id"                  component={GameForm} />
            <Route exact path="/admin/game/:id/round/:round_id"  component={RoundForm} />
            <Route exact path="/admin/game/:id/round"            component={RoundForm} />
            <Route       path="/admin/game/:id/participants"     component={ParticipantGrid} />
            </Switch>

            <PreviewPopup innerRef={c.previewRef} />
        </div>;
    }

    componentWillMount() {
        const c = this;
        const entity_id = c.props.match.params.id;

        c.controller.editExisting(entity_id);
    }
}

export default connect(
    /* mapStateToProps */ state => ({
        state__: state
    }),
    /* mapDispatchToProps */ state => ({
    })
)(GameEditor);
