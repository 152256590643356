import history from '../misc/history';


class FormDelegate {
    constructor(controller) {
        this.controller = controller;
    }

    didSubmit(json_response, action) {
        const c = this.controller;

        // navigate to edit url
        if (!c.state.entity_id) {
            history.push(`/admin/${c.entity_name}/${json_response.id}`);
        }
    }

    didDelete() {
        const c = this.controller;

        if (history.length <= 1) {
            history.replace('/admin');
        } else {
            history.goBack();
        }
    }
}

export default FormDelegate;
