import React from 'react';
import PropTypes from 'prop-types';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListItemText from '@material-ui/core/ListItemText';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import registry from 'react-mui-lib/registry';
import { red } from '@material-ui/core/colors';


const itemStyles = {
    menuItem: {
        display: 'inline-flex'
    },
    litext: {
        padding: '0'
    }
};

const _item = ({to, label, classes}) =>
    <Route path={to} exact children={({ match }) =>
        <MenuItem component={Link}
                  to={to}
                  selected={!!match}
                  className={classes.menuItem}>
            <ListItemText primary={label}
                          className={classes.litext} />
        </MenuItem>
    } />;

const Item = withStyles(itemStyles)(_item);

const _btn_item = ({onClick, label, classes}) =>
    <MenuItem onClick={onClick}
              className={classes.menuItem}>
        <ListItemText primary={label}
                      className={classes.litext} />
    </MenuItem>;

const BtnItem = withStyles(itemStyles)(_btn_item);


const selectorStyles = {
    container: {
        marginBottom: '24px'
    },
    menuList: {
    },
    text: {
        display: 'inline-block',
        padding: '16px 8px',
        color: 'rgba(0, 0, 0, 0.6)'
    }
};

class RoundSelector extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('round_selector_controller');
        this.game_controller = registry.get('game_form_controller');
    }

    render() {
        const c = this;
        const { classes } = c.props;
        const { rows, game_id } = c.controller.state;

        const game_name = c.game_controller.getModel('name').getModelValue();

        return <Card className={classes.container}>
            <CardContent>
                <MenuList className={classes.menuList}>
                    <Item to={`/admin/game/${game_id}`}
                          label={game_name || "Игра"} />

                    <span className={classes.text}>
                        Задания:
                    </span>

                    {rows.map((row, key) =>
                        <Item key={key}
                              to={`/admin/game/${game_id}/round/${row.id}`}
                              label={`${row.round_no}`} />
                    )}

                    <Item to={`/admin/game/${game_id}/round`}
                          label="+" />

                    <Item to={`/admin/game/${game_id}/participants`}
                          label="Участники" />

                    <BtnItem label="Предпросмотр"
                             onClick={() => c.props.openPreview()} />
                </MenuList>
            </CardContent>
        </Card>;
    }

    componentWillMount() {
        this.controller.loadForGameId(this.props.game_id);
    }
}

RoundSelector.propTypes = {
    game_id: PropTypes.string.isRequired,
};

export default connect(
    /* mapStateToProps */ state => ({
        state__: state
    }),
    /* mapDispatchToProps */ state => ({
    })
)(withStyles(selectorStyles)(RoundSelector));
