import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Icon from  '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import EditButton from "react-mui-lib/grid-widgets/EditButton";
import Grid from 'react-mui-lib/grid-widgets/Grid';
import Search from 'react-mui-lib/grid-widgets/filters/Search';
import registry from "react-mui-lib/registry";
import ConfirmDeleteDialog from './ConfirmDeleteDialog';

import { ReactComponent as Apple } from '../assets/apple.svg';


class AppUserGrid extends React.Component {
    deleteItem(item_id) {
        this.refs.dialog.open(item_id);
    }

    constructor(props) {
        super(props);

        this.controller = registry.get('appuser_grid_controller');

        this.columns = [{
            key: 'id',
            template: ({val}) =>
                <div style={{whiteSpace: 'nowrap'}}>
                     <IconButton onClick={() => this.deleteItem(val)}>
                        <Icon>delete</Icon>
                    </IconButton>
                    <EditButton to={`/admin/appuser/${val}`} />
                    {val}
                </div>
        }, {
            key: 'nickname',
            label: 'Никнейм'
        }, {
            key: 'status',
            label: 'Статус'
        }, {
            key: 'locality.name',
            label: 'Нас. пункт',
            template: ({val}) =>
                <span>{val}</span>
        }, {
            key: 'platform',
            label: 'Платформа',
            template: ({val}) =>
                val === 'ANDROID' ?
                    <Tooltip title="Android">
                        <Icon style={{color: 'green'}}>android</Icon>
                    </Tooltip> :
                    <Tooltip title="Apple">
                        <Apple/>
                    </Tooltip>
        }, {
            key: 'fcm_token_status',
            label: 'Token',
                template: ({val}) => {
                    if (val === 'PRESENT')
                        return <Tooltip title="Есть">
                            <span style={{color: 'green'}}>✔</span>
                        </Tooltip>;
                    else if (val === 'REMOVED')
                        return <Tooltip title="Удален">
                            <span style={{color: 'red'}}>✘</span>
                        </Tooltip>;
                    else 
                        return <Tooltip title="Нет">
                            <span>—</span>
                        </Tooltip>;
                }
        }];
    }

    render() {
        const c = this;

        return <Grid controller={c.controller}
                     columns={c.columns}
                     title="Пользователи приложения">
            <Search controller={c.controller}/>
            <ConfirmDeleteDialog ref="dialog" />
        </Grid>;
    }

    componentWillMount() {
        const c = this;
        c.controller.loadIfNeeded();
    }
}

export default AppUserGrid;
