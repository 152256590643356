import _ from 'lodash';
import {
    Model,
    StringSchema, NumberSchema,
    MapSchema, ListSchema,
    required, uuid
} from 'persistent-models';

import FormController from 'react-mui-lib/form/controller';
import FormDelegate from 'react-mui-lib/form/delegate';
import registry from 'react-mui-lib/registry';


const schema = new MapSchema({
    name: StringSchema({
        validator: required('Поле должно быть заполнено')
    }),
    localities: ListSchema(new MapSchema({
        id: StringSchema(),
        name: StringSchema(),
        default_map: StringSchema()
    }))
});


class Delegate extends FormDelegate {
    didSubmit(json_response) {
        registry.get('region_list_controller').needsRefresh();
        super.didSubmit(json_response);
    }
}


export default new FormController({
    schema,
    entity_name: 'region',
    state_prefix: 'region_form',
    Delegate
});
