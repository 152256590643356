
/**
 *  https://webpack.js.org/guides/public-path/
 *  This module must be the first import in the main application file (src/index.js).
 */
function set_webpack_public_path(script_id) {
    const script = document.getElementById(script_id);

    if (!script) {
        const msg = `Error setting __webpack_public_path__: cannot find <script> with id="${script_id}"`;
        console.error(msg);
        alert(msg);
        return;
    }

    let path = script.getAttribute('src');
    path = path.substr(0, path.lastIndexOf("/"));  // remove file name
    path = path.split('/');
    path.pop();  // remove static/js
    path.pop();
    path = path.join('/');

    if (path.substr(-1) != '/') {
        path = path + '/';
    }

    // eslint-disable-next-line no-undef
    __webpack_public_path__ = path;
}

if (process.env.NODE_ENV === 'production') {
    set_webpack_public_path('app-bundle');
}
