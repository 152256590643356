import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';

import { errors } from 'react-mui-lib/form-widgets/errors';


const styles = {
    mediumWidth: {
        width: '250px'
    },
    fullWidth: {
        width: '100%'
    },
    container: {
        display: 'inline-flex',
        marginTop: '16px'
    }
};


class DatepickerField extends React.Component {
    render() {
        const c = this;
        const {
            path,
            label,
            classes  // injected by withStyles()
        } = c.props;
        const controller = c.context.controller;
        const model = controller.getModel(path);

        if (model.is('hidden')) {
            return null;
        }

        // TODO const id = path

        const editable = controller.isEditable() && !model.is('readonly');
        const valid = model.isValid() || (!model.isDirty() && !controller.state.submitted);

        return <div className={classes.container}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='ru' moment={moment}>
                <DateTimePicker label={label}
                                format="DD.MM.YYYY HH:mm"
                                ampm={false}
                                keyboard
                                disablePast
                                cancelLabel="Отмена"
                                clearLabel="Удалить"
                                value={model.getViewValue()}
                                helperText={valid ? undefined : errors(model)}
                                error={!valid}
                                onChange={val =>
                                    controller.valueChanged(path, val)
                                }
                                onError={(val, error) =>
                                    controller.valueChanged(path, val)
                                } />
            </MuiPickersUtilsProvider>
            {/* {valid ? null : errors(model)} */}
        </div>;
    }
}

DatepickerField.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.string
};

DatepickerField.contextTypes = {
    controller: PropTypes.object
};

export default withStyles(styles)(DatepickerField);
