import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

import Form from 'react-mui-lib/form-widgets/Form';
import TextField from 'react-mui-lib/form-widgets/TextField';
import ImageField from 'react-mui-lib/form-widgets/ImageUploadField';
import Select from 'react-mui-lib/form-widgets/Select';

import registry from "react-mui-lib/registry";
import DatepickerField from '../locallib/DatepickerField';


class RegionForm extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('region_form_controller');

    }

    render() {
        const c = this;

        const localities = c.controller.getModel('localities').getViewValue();

        return <Form controller={c.controller}
                     title_new="Новый ркгион"
                     title_edit="Регион">

            <div>
                <TextField path="name"
                           label="Название" />
            </div>

            <h4>Населенные пункты</h4>
            <table>
                {localities.map(loc => <tr>
                    <td>{loc.name}</td>
                    <td>
                        { loc.default_map.center &&
                            JSON.stringify(loc.default_map.center)
                        }
                    </td>
                </tr>)}
            </table>

        </Form>;
    }

    init(props) {
        const c = this;
        const entity_id = props.match.params.id;

        if (entity_id === undefined) {
            c.controller.editNew();
        } else {
            c.controller.editExisting(entity_id);
        }
    }

    componentWillMount() {
        this.init(this.props);
    }

    componentWillReceiveProps(new_props) {
        const c = this;

        if (new_props.location.pathname !== c.props.location.pathname) {
            c.init(new_props);
        }
    }
}

export default connect(
    /* mapStateToProps */ state => ({
        state__: state
    }),
    /* mapDispatchToProps */ state => ({
    })
)(RegionForm);
