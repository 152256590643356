"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function Invalid(message) {
  var stop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  this.name = 'Invalid';
  this.message = message;
  this.stop = stop;
  this.stack = Error(this.message).stack; // http://es5.github.io/#x15.11.1
}

Invalid.prototype = Object.create(Error.prototype);
Invalid.prototype.constructor = Invalid;
var _default = Invalid;
exports.default = _default;