import _ from 'lodash';

import {
    Schema, StringSchema, NumberSchema, DateTimeSchema, MapSchema,
    required, length, regex
} from 'persistent-models';

import registry from 'react-mui-lib/registry';
import FormController from 'react-mui-lib/form/controller';

import FormDelegate from '../locallib/form-delegate';
import history from "../misc/history";


class ForeignKeySchema extends Schema {
    format(val) {
        return val === null ? '' : val;
    }

    parse(api, view_val) {
        return view_val === '' ? null : view_val;
    }
}


class GeofenceSchema extends Schema {
    constructor(options, defaults = {}) {
        super(options, {
            initialValue: {},
        }, defaults);
    }

    isEmpty(data) {
        return _.isEmpty(data.value);
    }
}


function _selectable_rounds() {
    return registry.get('game_form_controller')
        .getModel('round_mode').getModelValue() === 'SELECTABLE';
}


const schema = new MapSchema({
    game_id: StringSchema(),    // no widget, populated automatically, TODO submit only when new
    round_no: NumberSchema(),   // no widget, populated automatically, TODO submit only when new
    is_published: new Schema({
        initialValue: true
    }),  // TODO Boolean
    goal_type: new ForeignKeySchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    beacon_id: new ForeignKeySchema({  // empty value is null
        validate: function () {
            const beacon_selected = this.forPath('goal_type').getModelValue() === 'BEACON';

            this.setFlag('hidden', !beacon_selected);
            this.setFlag('submit', beacon_selected);
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    geofence: new GeofenceSchema({
        validate: function () {
            const geo_selected = this.forPath('goal_type').getModelValue() === 'GEOFENCE';

            this.setFlag('hidden', !geo_selected);
            this.setFlag('submit', geo_selected);
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    short_desc: StringSchema({
        validate: function () {
            const selectable_rounds = _selectable_rounds();

            this.setFlag('hidden', !selectable_rounds);
            this.setFlag('submit', selectable_rounds);

            this.callValidator(required('Поле должно быть заполнено'));
            this.callValidator(length(0, 30, 'Название не должно быть длиннее 30 символов'));
        }
    }),
    description: StringSchema({
        validate: function () {
            const goal_enabled = this.forPath('goal_type').getModelValue() !== 'NONE';

            this.setFlag('hidden', !goal_enabled);
            this.setFlag('submit', goal_enabled);

            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    description_image: StringSchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    audio_file: StringSchema({
    }),
    // codes
    has_codes: new Schema({
        initialValue: false,
        submit: false
    }),
    code_mode: new ForeignKeySchema({
        validate: function () {
            const goal_is_none = this.forPath('goal_type').getModelValue() === 'NONE';

            this.callValidator(required('Поле должно быть заполнено'));

            if (goal_is_none) {
                this.callValidator(function (api) {
                    const valid = api.getModelValue() !== 'NONE';
                    api.setValid(
                        'code-required-when-goal-type-is-none',
                        valid,
                        'Задание без указания навигации должно содержать проверочный код'
                    );
                });
            }
        }
    }),
    single_code: StringSchema({
        validate: function () {
            const enabled = this.forPath('code_mode').getModelValue() === 'SINGLE';

            this.setFlag('hidden', !enabled);
            this.setFlag('submit', enabled);

            this.callValidator(required('Поле должно быть заполнено'));
            this.callValidator(regex(/^[А-Яа-я\d]{3,30}$/, 'Введите от 3 до 30 букв и/или цифр'));
        }
    }),
    code_message: StringSchema({
        validate: function () {
            const code_mode = this.forPath('code_mode').getModelValue();
            const enabled = code_mode === 'GENERATED' || code_mode === 'SINGLE';

            this.setFlag('hidden', !enabled);
            this.setFlag('submit', enabled);

            this.callValidator(required('Поле должно быть заполнено'));
        }
    })
});


class Delegate extends FormDelegate {
    didSubmit(json_response) {
        const c = this.controller;

        if (!c.state.entity_id) {
            registry.get('round_selector_controller').reload();
            history.push(`/admin/game/${c.state.data.game_id.value}/round/${json_response.id}`);
        } else {
            if (c.getModel('code_mode').getModelValue() === 'GENERATED') {
                // codes were generated
                c.valueChanged('has_codes', true);
            }
        }
    }

    didDelete() {
        registry.get('round_selector_controller').reload();
        super.didDelete();
    }
}


export default new FormController({
    schema,
    entity_name: 'round',
    state_prefix: 'round_form',
    Delegate
});
