import React from 'react';

import Grid from 'react-mui-lib/grid-widgets/Grid';
import EditButton from "react-mui-lib/grid-widgets/EditButton";
import registry from "react-mui-lib/registry";
import { format_timestamp } from '../utils';
import { has_superaccess } from "../utils";


const columns = [{
    key: 'id',
    template: ({val, row}) =>
        <div style={{whiteSpace: 'nowrap'}}>
            <EditButton to={`/admin/region/${val}`} />
        </div>
}, {
    key: 'name',
    label: 'Название'
}];


class RegionGrid extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('region_grid_controller');
    }

    render() {
        const c = this;

        return <Grid controller={c.controller}
                     columns={columns}
                     title="Регионы"/>;
    }

    componentWillMount() {
        const c = this;
        c.controller.loadIfNeeded();
    }
}

export default RegionGrid;
