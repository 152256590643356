import _ from 'lodash';
import {
    Model,
    StringSchema, NumberSchema, MapSchema, PhoneSchema,
    required, uuid
} from 'persistent-models';

import FormController from 'react-mui-lib/form/controller';
import registry from 'react-mui-lib/registry';

import FormDelegate from '../locallib/form-delegate';


const schema = MapSchema({
    region_id: NumberSchema({
        submit: false
    }),
    locality_id: StringSchema({
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    name: StringSchema({
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    description: StringSchema(),
    organizer_name: StringSchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    organizer_inn: StringSchema({
        validate: function () {
            const inn1 = /^\d{10}$/;
            const inn2 = /^\d{12}$/;

            this.callValidator(required('Поле должно быть заполнено'));
            this.callValidator(api => {
                const value = api.getModelValue();
                const valid = inn1.test(value) || inn2.test(value);
                api.setValid('regex', valid, "Введите 10 или 12 цифр ИНН");
            })
        }
    }),
    organizer_phone: PhoneSchema({
        parser_message: 'Введите номер телефона в виде +7 ххх ххх хх хх',
        initialValue: '+7',
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    })
});


class Delegate extends FormDelegate {
    didSubmit(json_response) {
        registry.get('franchise_list_controller').load();
        super.didSubmit(json_response);
    }

    didDelete() {
        registry.get('franchise_list_controller').load();
        super.didDelete();
    }
}


export default new FormController({
    schema,
    entity_name: 'franchise',
    state_prefix: 'franchise_form',
    reduceAfterLoaded(api) {
        const locality_id = api.forChild('locality_id').getModelValue();
        const locality = registry.get('locality_list_controller')
            .state.rows.filter(row => row.id === locality_id);

        if (locality.length !== 1) {
            return;
        }

        api.forChild('region_id').setModelValue(locality[0].region_id);
    },
    reduceAfterValueChanged(api, action) {
        if (action.path !== 'region_id') return;

        if (api.old_data_node.value === api.data_node.value) {
            return;
        }

        api.forPath('locality_id').setViewValue('');
    },
    Delegate
});
