import ClientsideGridController from "react-mui-lib/grid-clientside/controller";

import registry from "react-mui-lib/registry";


class AdminHelpController extends ClientsideGridController {
    getById(id) {
        const rows = this.state.rows;

        const row = rows.find(el => el.id === id);

        return row === undefined ? '' : row.content;
    }

    save(id, content) {
        const message_controller = registry.get('message_controller');
        const rest_client = registry.get('rest_client');

        return rest_client.updateEntityForId(
            this.options.entity_name,
            id,
            {id, content}
        ).then(json => {
            message_controller.showInfo('Данные сохранены');
            return this.load();
        }, error => {
            message_controller.showException('Ошибка сохранения', error);
        });
    }
}

registry.register('admin_help_controller', new AdminHelpController({
    entity_name: 'admin-help',
    state_prefix: 'admin_help_list'
}));
