import {
    Schema, MapSchema, StringSchema,
    NumberSchema, DateTimeSchema,
    required, length
} from 'persistent-models';
import FormController from 'react-mui-lib/form/controller';
import registry from 'react-mui-lib/registry';


const schema = new MapSchema({
    title: StringSchema({
        validator: required('Поле должно быть заполнено')
    }),
    message: StringSchema({
        validator: required('Поле должно быть заполнено')
    }),
    filter: StringSchema({
        validator: required('Поле должно быть заполнено')
    }),
    play_sound: new Schema({
        initialValue: false
    })
});


class Controller extends FormController {
    sendMessages() {
       const game_form_controller = registry.get('game_form_controller');
       const message_controller = registry.get('message_controller');

       this.dispatch({
            type: 'SUBMIT_REQUESTED'
        });

        if (!this.getModel().isValid()) {
            console.log('Form is invalid:', this.getModel().getErrors());
            return false;
        }

        this.dispatch({
            type: 'SUBMITTING'
        });

        return registry.get('rest_client').post(
            `game/${game_form_controller.state.entity_id}/send_messages`,
            this.getModel().getModelValue()
        ).then(data => {
            this.dispatch({
                type: 'SUBMIT_SUCCESS',
                data
            });

            const s = data.fcm_status;

            let msg = `Отправлено ${s.n_success}, не отправлено ${s.n_failure}, ошибок ${s.n_error}`;
            if (s.messages) {
                msg = msg + '\n' + s.messages;
            }

            message_controller.showInfo('Сообщения отправлены', msg);
        }, error => {
           this.dispatch({
                type: 'SUBMIT_FAILED',
                error
            });

            message_controller.showException('Ошибка отправки сообщения', error);
        });
    }
}


export default new Controller({
    schema,
    state_prefix: 'game_notification_form'
});
