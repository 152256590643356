import _ from 'lodash';

import BaseController from 'react-mui-lib/controller/base-controller';
import BaseReducer from 'react-mui-lib/controller/base-reducer';


class Reducer extends BaseReducer {
    INITIAL() {
        return {
            sidebar_open: true,
        }
    }

    TOGGLE_SIDEBAR(state, action) {
        return _.assign({}, state, {
            sidebar_open: !state.sidebar_open
        });
    }
}


class Controller extends BaseController {
    constructor(state_prefix) {
        super('local', state_prefix, Reducer);
    }

    toggleSidebar() {
        this.dispatch({
            type: 'TOGGLE_SIDEBAR'
        });
    }
}

export default new Controller('local');
