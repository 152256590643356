import React from 'react';

import { withStyles } from '@material-ui/core/styles';


const styles = {
    container: {
        display: 'flex',
        alignItems: 'baseline'
    }
};

class FieldWithHelp extends React.Component {
    render() {
        const c = this;
        const { show = true, children, classes } = c.props;

        if (!show) {
            return null;
        }

        return <div className={classes.container}>
            {children}
        </div>;
    }
}

export default withStyles(styles)(FieldWithHelp);
