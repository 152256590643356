import moment from 'moment';

import { Schema, Invalid } from 'persistent-models';


class MomentSchema extends Schema {
    constructor(options, defaults = {}) {
        super(options, {
            initialValue: null, // moment(),
            parser_message: 'Bad date format'
        }, defaults);
    }

    format(value) {
        return value;
    }

    parse(api, value) {
        if (value === null) {
            return null;
        }

        if (!value.isValid()) {
            throw new Invalid(this.options.parser_message);
        }

        return value;
    }

    toJSON(data) {
        if (data.value === null) {
            return null;
        }

        // http://stackoverflow.com/questions/25725019/how-do-i-format-a-date-as-iso-8601-in-moment-js
        return data.value.format(); 
    }

    fromJSON(json_data) {
        if (json_data === null) {
            return null;
        }

        // json_value is an ISO string http://momentjs.com/docs/#/parsing/string/
        return moment(json_data);
    }    
}

export default MomentSchema;
