import _ from 'lodash';
import {
    Model,
    StringSchema, NumberSchema, MapSchema,
    required, uuid
} from 'persistent-models';

import FormController from 'react-mui-lib/form/controller';
import FormDelegate from 'react-mui-lib/form/delegate';
import registry from 'react-mui-lib/registry';


const schema = new MapSchema({
    region_id: NumberSchema({
        validator: required('Поле должно быть заполнено')
    }),
    name: StringSchema({
        validator: required('Поле должно быть заполнено')
    })
});


class Delegate extends FormDelegate {
    didSubmit(json_response) {
        registry.get('locality_list_controller').needsRefresh();
        super.didSubmit(json_response);
    }
}


export default new FormController({
    schema,
    entity_name: 'locality',
    state_prefix: 'locality_form',
    Delegate
});
