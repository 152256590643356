import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';

import Help from '@material-ui/icons/Help';
import Edit from '@material-ui/icons/Edit';

import registry from 'react-mui-lib/registry';

import { has_superaccess } from '../utils';


const StyledTooltip = withStyles({
    tooltip: {
        fontSize: '1em'
    }
})(Tooltip);


class HelpEditDialog extends React.Component {
    open() {
        const c = this;
        const { id } = c.props;

        c.setState({
            dialog_open: true,
            editing: true,
            content: c.controller.getById(id)
        });
    }

    constructor(props) {
        super(props);

        this.controller = registry.get('admin_help_controller');

        this.state = {
            dialog_open: false,
            editing: false,
            content: ''
        };
    }

    render() {
        const c = this;
        const { id } = c.props;
        const { dialog_open, editing, content } = c.state;

        return <Dialog fullWidth={true}
                       open={dialog_open}
                       onClose={c.onCancel.bind(c)}>
            <DialogTitle>
                Текст справки: {id}
            </DialogTitle>
            <DialogContent>
                <Input fullWidth={true}
                       multiline={true}
                       value={content}
                       disabled={!editing}
                       onChange={c.onChange.bind(c)} />
            </DialogContent>
            <DialogActions>
                <Button variant="raised"
                        color="primary"
                        disabled={!editing}
                        onClick={c.onSave.bind(c)}>
                    Сохранить
                </Button>
                <Button color="primary"
                        disabled={!editing}
                        onClick={c.onCancel.bind(c)}>
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onChange(e) {
        const c = this;

        c.setState({
            content: e.target.value
        });
    }

    onSave() {
        const c = this;

        c.setState({
            editing: false
        });

        c.controller.save(c.props.id, c.state.content).then(() =>
        c.setState({
            dialog_open: false
        }));
    }

    onCancel() {
        const c = this;

        c.setState({
            dialog_open: false,
            editing: false
        });
    }
}


const styles = theme => ({
    buttonBlock: {
        marginTop: '12px',
        whitespace: 'no-wrap'
    }
});

class HelpButton extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('admin_help_controller');
        this.help_edit_dialog = React.createRef();
    }

    render() {
        const c = this;
        const { classes } = c.props;

        const id = c.context.controller.entity_name + '.' + c.props.id;
        const content = c.controller.getById(id);
        const enable_editing = has_superaccess();

        let tooltip;

        if (enable_editing) {
            tooltip = <div>
                {c.controller.getById(id)}
                <div className={classes.buttonBlock}>
                    {id}
                    &nbsp;
                    <Button variant="contained"
                            color="secondary"
                            onClick={c.onEdit.bind(c)}>
                        <Edit />
                    </Button>
                </div>
            </div>;
        } else {
            if (!content) return null;

            tooltip = c.controller.getById(id);
        }

        return <>
            <StyledTooltip title={tooltip}
                           interactive={has_superaccess()}>
                <IconButton onClick={c.onClick.bind(c)}>
                    <Help/>
                </IconButton>
            </StyledTooltip>

            { enable_editing && 
                <HelpEditDialog id={id}
                                ref={c.help_edit_dialog} />
            }
        </>;
    }

    onClick() {
        //
    }

    onEdit() {
        const c = this;

        c.help_edit_dialog.current.open();
    }
}

HelpButton.contextTypes = {
    controller: PropTypes.object
};

export default withStyles(styles)(HelpButton);
