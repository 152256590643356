"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.required = required;
exports.range = range;
exports.length = length;
exports.regex = regex;
exports.uuid = uuid; // var _ = require('lodash');
// import { Invalid } from './validate';

function required() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Field is required';
  return function (api) {
    api.setValid('required', !api.isEmpty(), message);
  };
}

function range(min, max) {
  var message = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'Value out of range';
  return function (api) {
    var value = api.getModelValue();
    var valid = true;

    if (min !== undefined) {
      valid = valid && value >= min;
    }

    if (max !== undefined) {
      valid = valid && value <= max;
    }

    api.setValid('range', valid, message);
  };
}

function length(min, max) {
  var message = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'Incorrect length';
  return function (api) {
    var value = api.getModelValue();
    var valid = true;

    if (min !== undefined) {
      if (value.length < min) {
        valid = false;
      }
    }

    if (max !== undefined) {
      if (value.length > max) {
        valid = false;
      }
    }

    api.setValid('length', valid, message);
  };
}

function regex(regexp) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Bad value';
  return function (api) {
    var value = api.getModelValue();
    var valid = regexp.test(value);
    api.setValid('regex', valid, message);
  };
}

function uuid() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Bad UUID';
  var re = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return function (api) {
    var value = api.getModelValue();
    var valid = re.test(value);
    api.setValid('uuid', valid, message);
  };
}
/*
export function identifier (message_) {
    const re = /^[A-Za-z0-9_\.-]+$/;
    const message = message_ || 'Invalid identifier';

    return {
        key: 'identifier',
        validate(value, setValid) {
            const valid = value === '' || re.test(value);
            //console.log('identifier.validate():', value, valid ? 'valid' : 'INVALID');
            setValid(valid, message);
            //if (!valid) {
            //    throw new Invalid(message);
            //}
        }
    };
}


export function email (message_) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    const message = message_ || 'Invalid email address';

    return {
        key: 'email',
        validate(value, setValid) {
            if (!value) {
                setValid(true);
                return;
            }

            const valid = re.test(value);
            setValid(valid, message);
        }
    };
}
*/

/*
var PasswordsMatch = function (path1, path2, message) {
    var message = message || 'Passwords do not match';

    return function (model) {
        //console.log('PasswordsMatch: model =', model);

        var valid = model.child(path1).getValue() === model.child(path2).getValue();

        model.child(path1).setValid(valid, 'passwords-match', message);
        model.child(path2).setValid(valid, 'passwords-match', message);
    }
};
 */