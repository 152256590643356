import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Form from 'react-mui-lib/form-widgets/Form';
import Text from 'react-mui-lib/form-widgets/Text';

import controller from './appuser-form-controller';


class DeviceInfo extends React.Component {
    render() {
        const c = this;
        const { path, ...etc } = c.props;
        const controller = c.context.controller;
        const model = controller.getModel(path);

        return <div {...etc}>
            <h4>Данные об устройстве:</h4>
            <ul>
                {_.map(model.getModelValue(), (val, key) =>
                    <li key={key}>
                        <code>{key}</code>:
                        {" "}
                        <code>{JSON.stringify(val)}</code>
                    </li>
                )}
            </ul>
        </div>;
    }
}

DeviceInfo.propTypes = {
    path: PropTypes.string.isRequired
};

DeviceInfo.contextTypes = {
    controller: PropTypes.object
};


class AppUserForm extends React.Component {
    render() {
        const has_fcm_token = controller.getModel('has_fcm_token').getModelValue();
        const fcm_token_updated = controller.getModel('fcm_token_updated').getModelValue();

        return <Form controller={controller}
                     title_new=""
                     title_edit="Пользователь приложения"
                     show_submit_btn={false}>

            <div>
                Телефон: <Text path="id" />
            </div>
            <div>
                Никнейм: <Text path="nickname" />
            </div>
            <div>
                Статус: <Text path="status" />
            </div>
            <div>
                Зарегистрирован: <Text path="registered" />
            </div>
            { controller.getModel('confirm_code').getModelValue() !== '' &&
                <div>
                    Код подтверждения: <Text path="confirm_code" />
                </div>
            }
            <div>
                FCM токен: <Text path="has_fcm_token" />
                { (fcm_token_updated !== null) &&
                    <>
                        , { has_fcm_token ? 'обновлен' : 'удален'}:&nbsp;
                        <Text path="fcm_token_updated" />
                    </>
                }
            </div>
            <div>
                <DeviceInfo path="device_info"/>
            </div>
        </Form>;
    }

    componentWillMount() {
        const entity_id = this.props.match.params.id;
        controller.editExisting(entity_id);
    }
}

//export default AppUserForm;
export default connect(
    /* mapStateToProps */ state => ({
        state__: state
    }),
    /* mapDispatchToProps */ state => ({
    })
)(AppUserForm);
