import React from "react";
import { Route, Switch, Redirect, Link } from "react-router-dom";

import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';

import ResponsiveDrawer from "react-mui-lib/ResponsiveDrawer";
import Snackbars from "react-mui-lib/messages-widgets/Snackbars";
import Messages from "react-mui-lib/messages-widgets/Messages";
import { has_superaccess, has_permission } from "../utils";

import BeaconGrid from "../beacon/BeaconGrid";
import BeaconForm from "../beacon/BeaconForm";
import GameGrid from "../game/GameGrid";
import GameForm from "../game/GameForm";
import GameEditor from "../game/GameEditor";
import FranchiseGrid from "../franchise/FranchiseGrid";
import FranchiseForm from "../franchise/FranchiseForm";
import PromoForm from "../promo/PromoForm";
import AppUserGrid from "../appuser/AppUserGrid";
import AppUserForm from "../appuser/AppUserForm";
import PromoGrid from "../promo/PromoGrid";
import UserGrid from "../user/UserGrid";
import UserForm from "../user/UserForm";
import RegionGrid from "../region/RegionGrid";
import RegionForm from "../region/RegionForm";


class ToolbarContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anchor: null
        };
    }

    render() {
        return <>
            <IconButton color="inherit"
                        ref={this.anchorEl}
                        onClick={e => this.setState({anchor: e.currentTarget})}>
                <AccountCircle />
            </IconButton>
            {window.Params !== undefined &&
                <Button color="inherit"
                        onClick={e => this.setState({anchor: e.currentTarget})}>
                    {window.Params.user.name}
                </Button>
            }
            <Menu id="menu-appbar"
                  anchorEl={this.state.anchor}
                  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  open={Boolean(this.state.anchor)}
                  onClose={() => this.setState({anchor: null})}>
                  {/* <MenuItem onClick={this.handleClose}>Пользователь</MenuItem> */}
                <MenuItem component="a" href="/auth/logout">
                    Выйти
                </MenuItem>
            </Menu>
        </>;
    }
}


function DrawerItem({label, link, permission, onClick}) {
    if (permission && !has_permission(permission)) {
        return null;
    }

    return <MenuItem component={Link}
                     to={link}
                     onClick={onClick}>
        <ListItemText primary={label} />
    </MenuItem>;
}


function DrawerContent({onItemClick}) {
    return <MenuList>
        <DrawerItem link="/admin/games"
                    label="Игры"
                    onClick={onItemClick} />
        <DrawerItem link="/admin/game"
                    label="Новая игра"
                    onClick={onItemClick} />
        <Divider />
        <DrawerItem link="/admin/beacons"
                    label="Маяки"
                    onClick={onItemClick} />
        <DrawerItem link="/admin/beacon"
                    label="Новый маяк"
                    permission="admin-panel/superaccess"
                    onClick={onItemClick} />
        <Divider />
        <DrawerItem link="/admin/promos"
                    label="Акции"
                    permission="admin-panel/promo-access"
                    onClick={onItemClick} />
        <DrawerItem link="/admin/promo"
                    label="Новая акция"
                    permission="admin-panel/promo-access"
                    onClick={onItemClick} />
        { has_permission('admin-panel/promo-access') &&
            <Divider />
        }
        <DrawerItem link="/admin/appusers"
                    label="Пользователи приложения"
                    permission="admin-panel/superaccess"
                    onClick={onItemClick} />
        { has_permission('admin-panel/superaccess') &&
            <Divider />
        }
        <DrawerItem link="/admin/users"
                    label="Пользователи админки"
                    permission="admin-panel/superaccess"
                    onClick={onItemClick} />
        <DrawerItem link="/admin/user"
                    label="Новый пользователь"
                    permission="admin-panel/superaccess"
                    onClick={onItemClick} />
        { has_permission('admin-panel/superaccess') &&
            <Divider />
        }
        <DrawerItem link="/admin/regions"
                    label="Регионы"
                    permission="admin-panel/superaccess"
                    onClick={onItemClick} />
        <DrawerItem link="/admin/franchises"
                    label="Франшизы"
                    permission="admin-panel/superaccess"
                    onClick={onItemClick} />
        <DrawerItem link="/admin/franchise"
                    label="Новая франшиза"
                    permission="admin-panel/superaccess"
                    onClick={onItemClick} />
    </MenuList>;
}


const ACRoute = ({ component: Component, permission = 'admin-panel/superaccess', ...rest }) =>
  <Route {...rest}
         render={props =>
            has_permission(permission) ?
                <Component {...props} /> :
                <Redirect to={{
                    pathname: '/admin',
                    state: { from: props.location }
                }}/>
         }/>;


class App extends React.Component {
    constructor(props) {
        super(props);

        this.drawer = React.createRef();
    }

    render() {
        const c = this;
        const { title } = c.props;

        return <ResponsiveDrawer title={title}
                                 drawerContent={<DrawerContent onItemClick={c.onDraweritemSelected.bind(c)} />}
                                 ToolbarContent={ToolbarContent}
                                 innerRef={c.drawer}>
            <Snackbars />
            <Messages />
            <Switch>
                <Redirect exact from='/' to='/admin/games'/>
                <Redirect exact from='/admin/' to='/admin/games'/>
                <Route         path='/admin/games' component={GameGrid}/>
                <Route   exact path='/admin/game' component={GameForm}/>
                <Route         path='/admin/game/:id' component={GameEditor}/>
                <Route         path='/admin/beacons' component={BeaconGrid}/>
                <ACRoute exact path='/admin/beacon' component={BeaconForm}/>
                <Route         path='/admin/beacon/:id' component={BeaconForm}/>
                <Route         path='/admin/appusers' component={AppUserGrid}/>
                <Route         path='/admin/appuser/:id' component={AppUserForm}/>
                <ACRoute       path='/admin/promos' component={PromoGrid} permission='admin-panel/promo-access' />
                <ACRoute exact path='/admin/promo' component={PromoForm} permission='admin-panel/promo-access' />
                <ACRoute       path='/admin/promo/:id' component={PromoForm} permission='admin-panel/promo-access' />
                <ACRoute       path='/admin/users' component={UserGrid} />
                <ACRoute exact path='/admin/user' component={UserForm}/>
                <ACRoute       path='/admin/user/:id' component={UserForm}/>
                <ACRoute       path='/admin/regions' component={RegionGrid}/>
                <ACRoute exact path='/admin/region' component={RegionForm}/>
                <ACRoute       path='/admin/region/:id' component={RegionForm}/>
                <ACRoute       path='/admin/franchises' component={FranchiseGrid}/>
                <ACRoute exact path='/admin/franchise' component={FranchiseForm}/>
                <ACRoute       path='/admin/franchise/:id' component={FranchiseForm}/>
            </Switch>
        </ResponsiveDrawer>;
    }

    onDraweritemSelected() {
        const c = this;

        c.drawer.current.close();
    }

    componentDidMount() {
        const loading = document.getElementById('loading');
        if (loading) {
            loading.parentNode.removeChild(loading);
        }
    }
}

export default App;
