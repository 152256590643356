import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';


const styles = theme => ({
    leftIcon: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing.unit
        }
    },
    btnLabel: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    leftIcon2: {
        marginRight: theme.spacing.unit
    },

});


class DeleteButton extends React.Component {
    state = {
        dialogOpen: false
    };

    render() {
        const c = this;
        const {
            label = 'Удалить',
            dialogTitle = 'Удалить объект?',
            classes,
            ...etc
        } = c.props;
        const { controller } = c.context;
        const { dialogOpen } = c.state;

        return <>
            <Button color="secondary"
                    disabled={!controller.isEditable()}
                    onClick={c.onClick.bind(c)}
                    {...etc}>
                <DeleteIcon className={classes.leftIcon} />
                <span className={classes.btnLabel}>
                    {label}
                </span>
            </Button>
            <Dialog open={dialogOpen}
                    onClose={c.onClose.bind(c)}>
                <DialogTitle>
                    {dialogTitle}
                </DialogTitle>
                 <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                </DialogContent> 
                <DialogActions>
                    <Button variant="contained"
                            color="secondary"
                            autoFocus
                            onClick={c.onDelete.bind(c)}>
                        <DeleteIcon className={classes.leftIcon2} />
                        Удалить
                    </Button>
                    <Button color="primary"
                            onClick={c.onClose.bind(c)}>
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>            
        </>;
    }

    onClick(e) {
        const c = this;

        e.preventDefault();

        c.setState({
            dialogOpen: true
        });
    }

    onClose() {
        const c = this;

        c.setState({
            dialogOpen: false
        });
    }

    onDelete() {
        const c = this;

        c.context.controller.delete();
        c.onClose();
    }
}

DeleteButton.propTypes = {
    label: PropTypes.string,
    dialogTitle: PropTypes.string
};

DeleteButton.contextTypes = {
    controller: PropTypes.object
};

export default withStyles(styles)(DeleteButton);
