import moment from 'moment';

export function has_permission(perm) {
    // development server
    if (window.Params === undefined) {
        return true;
    }

    return window.Params.user.permissions.includes(perm);
}

export function has_superaccess() {
    return has_permission('admin-panel/superaccess');
}

export function format_timestamp(ts) {
    var FORMAT = 'DD.MM.YYYY HH:mm:ss';

    if (ts === null) {
        return '';
    }

    return moment(ts).format(FORMAT);
}
