import moment from 'moment';

import registry from 'react-mui-lib/registry';
import {
    Schema, StringSchema, MapSchema,
    DateTimeSchema, required
} from 'persistent-models';
import FormController from 'react-mui-lib/form/controller';

import FormDelegate from '../locallib/form-delegate';
import MomentSchema from '../locallib/moment-schema';
import { has_superaccess } from '../utils';


const schema = new MapSchema({
    franchise_id: StringSchema({
        validate: function () {
            this.setFlag('hidden', !has_superaccess());
            this.setFlag('submit', has_superaccess());
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    is_published: new Schema({
        initialValue: true
    }),
    start_date: new MomentSchema({
        initialValue: moment(),
        parser_message: 'Введите дату и время, например, 20.08.2017 12:00',
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    end_date: new MomentSchema({
        initialValue: moment(),
        parser_message: 'Введите дату и время, например, 20.08.2017 12:00',
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    title: StringSchema({
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    content: StringSchema({
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    image: StringSchema({
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    link: StringSchema()
});


class Controller extends FormController {
    sendMessages(message) {
        const message_controller = registry.get('message_controller');

        registry.get('rest_client').post(
            `promo/${this.state.entity_id}/send_messages`,
            message
        ).then(data => {
            const s = data.fcm_status;

            let msg = `Отправлено ${s.n_success}, не отправлено ${s.n_failure}, ошибок ${s.n_error}`;
            if (s.messages) {
                msg = msg + '\n' + s.messages;
            }

            message_controller.showInfo('Сообщения отправлены', msg);
        }, error => {
            message_controller.showException('Ошибка отправки сообщения', error);
        });
    }
}


class Delegate extends FormDelegate {
    didSubmit(json_response) {
        registry.get('promo_grid_controller').needsRefresh();
        super.didSubmit(json_response);
    }

    didDelete() {
        registry.get('promo_grid_controller').needsRefresh();
        super.didDelete();
    }
}


export default new Controller({
    schema,
    entity_name: 'promo',
    state_prefix: 'promo_form',
    Delegate
});
