import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';

import Form from 'react-mui-lib/form-widgets/Form';
import TextField from 'react-mui-lib/form-widgets/TextField';
import Checkbox from 'react-mui-lib/form-widgets/Checkbox';
import Select from 'react-mui-lib/form-widgets/Select';
import RadioList from 'react-mui-lib/form-widgets/RadioList';
import ImageField from 'react-mui-lib/form-widgets/ImageUploadField';
import registry from 'react-mui-lib/registry';

import GeoField from './GeoField';
import DeleteButton from '../locallib/DeleteButton';
import HelpButton from '../locallib/HelpButton';
import FieldWithHelp from '../locallib/FieldWithHelp';


class Codes extends React.Component {
    render() {
        const c = this;
        const controller = c.context.controller;
        const has_codes = controller.getModel('has_codes').getModelValue();
        const use_codes = controller.getModel('code_mode').getModelValue() === 'GENERATED';
        const { entity_id } = controller.state;

        if (entity_id === null) {
            return null;
        }

        if (has_codes && use_codes) {
            return <span>
                <a href={`/admin/round/${entity_id}/print-codes`}
                   target="newtab">
                    распечатать коды
                </a>
                <HelpButton id="print-codes" />
            </span>;
        } else {
            return null;
        }
    }
}

Codes.contextTypes = {
    controller: PropTypes.object
};


const styles = {
    hidden: {
        display: 'none'
    },
    conditional_field_block: {
        borderLeft: '1px solid #666',
        paddingLeft: '24px'
    }
};


class RoundForm extends React.Component {
    constructor(props) {
        super(props);
        this.controller = registry.get('round_form_controller');
        this.game_controller = registry.get('game_form_controller');
        this.round_selector_controller = registry.get('round_selector_controller');
        this.beacon_list_controller = registry.get('beacon_list_controller');
    }

    render() {
        const c = this;
        const { classes } = c.props;  // injected by withStyles()
        const round_no = c.controller.getModel('round_no').getModelValue();

        const goal_type_options = [{
            val: 'NONE',
            label: <span>Нет <HelpButton id="goal_type.none" /></span>
        }, {
            val: 'BEACON',
            label: <span>Маяк <HelpButton id="goal_type.beacon" /></span>
        }, {
            val: 'GEOFENCE',
            label: <span>Геозона <HelpButton id="goal_type.geofence" /></span>
        }];

        const code_mode_options = [{
            val: 'NONE',
            label: <span>Нет <HelpButton id="code_mode.none" /></span>
        }, {
            val: 'GENERATED',
            label: <span>Случайные одноразовые коды <HelpButton id="code_mode.generated" /></span>
        }, {
            val: 'SINGLE',
            label: <span>Единственный код <HelpButton id="code_mode.single" /></span>
        }];

        return <Form controller={c.controller}
                     title_new="Новое задание"
                     title_edit={`Задание #${round_no}`}
                     actions_edit={[
                        <span style={{flexGrow: 1}} />,
                        <DeleteButton dialogTitle="Удалить задание?" />
                    ]}>

            <FieldWithHelp>
                <Checkbox path="is_published"
                          label="Опубликовано" />
                <HelpButton id="is_published" />
            </FieldWithHelp>
            <div>
                <RadioList path="goal_type"
                           options={goal_type_options}
                           label="Навигация" />
            </div>
            <FieldWithHelp show={!c.controller.getModel('beacon_id').is('hidden')}>
                <Select path="beacon_id"
                        options={c.beacon_list_controller.getAsOptions()}
                        label="Маяк" />
                <HelpButton id="beacon_id" />
            </FieldWithHelp>
            <FieldWithHelp show={!c.controller.getModel('geofence').is('hidden')}>
                <GeoField path="geofence"
                          label="Геозона"/>
                <HelpButton id="geofence" />
            </FieldWithHelp>
            <FieldWithHelp show={!c.controller.getModel('short_desc').is('hidden')}>
                <TextField path="short_desc"
                           label="Краткое описание (выводится в списке заданий, не более 30 символов)"/>
                <HelpButton id="short_desc" />
            </FieldWithHelp>
            <FieldWithHelp show={!c.controller.getModel('description').is('hidden')}>
                <TextField path="description"
                           label="Описание"
                           multiLine={true} />
                <HelpButton id="description" />
            </FieldWithHelp>
            <FieldWithHelp>
                <ImageField path="description_image"
                            config={{
                                category: 'round-images',
                                large_variant: '',
                                enable_delete: false
                            }}
                            label="Изображение" />
                <HelpButton id="description_image" />
            </FieldWithHelp>
            <div>
                <ImageField path="audio_file"
                            config={{
                                type: 'audio',
                                category: 'round-audio',
                                max_file_size_mbytes: 10
                            }}
                            label="Аудио" />
            </div>
            <div>
                <RadioList path="code_mode"
                          label="Проверочный код"
                          options={code_mode_options} />
            </div>
            <div className={classNames({
                [classes.hidden]: c.controller.getModel('code_mode').getViewValue() === 'NONE',
                [classes.conditional_field_block]: true
            })}>
                <FieldWithHelp show={!c.controller.getModel('single_code').is('hidden')}>
                    <TextField path="single_code"
                            label="Код (от 3 до 30 букв и/или цифр)" />
                    <HelpButton id="single_code" />
                </FieldWithHelp>
                <FieldWithHelp show={!c.controller.getModel('code_message').is('hidden')}>
                    <TextField path="code_message"
                            label="Пояснение и/или вопрос при вводе кода"
                            multiLine={true} />
                    <HelpButton id="code_message" />
                </FieldWithHelp>
                <div>
                    <Codes />
                </div>
            </div>
        </Form>;
    }

    componentWillMount() {
        this.init(this.props);
    }

    componentWillReceiveProps(new_props) {
        if (new_props.location.pathname !== this.props.location.pathname) {
            this.init(new_props);
        }
    }

    init(props) {
        const c = this;
        const { id: game_id, round_id } = props.match.params;

        if (round_id === undefined) {
            c.controller.editNew({
                game_id
            });
        } else {
            c.controller.editExisting(round_id);
        }
    }
}

export default withStyles(styles)(RoundForm);
