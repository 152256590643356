import React from 'react';
import moment from 'moment';

import IconButton from '@material-ui/core/IconButton';
import Icon from  '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import Grid from 'react-mui-lib/grid-widgets/Grid';
import controller from './participant-grid-controller';
import registry from "react-mui-lib/registry";
import WinnerListDialog from './WinnerListDialog';
import ParticipantFormDialog from './ParticipantFormDialog';
import AddParticipantsDialog from './AddParticipantsDialog';


const columns = c => [{
    key: '',
    template: ({val, row}) =>
        <div style={{whiteSpace: 'nowrap'}}>
            <Tooltip title="xxcxcxc">
                <IconButton onClick={() => c.participant_form_dialog.current.open(row.app_user_id)}>
                    <Icon>edit</Icon>
                </IconButton>
            </Tooltip>

            <Tooltip title="Удалить участника">
                <IconButton onClick={() => controller.deleteFor(row.app_user_id)}>
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
        </div>
}, {
    key: 'app_user_id',
    label: 'Телефон'
}, {
    key: 'app_user.nickname',
    label: 'Никнейм'
}, {
    key: 'completed',
    label: 'завершил',
    template: ({val, row}) =>
        val === null ?
            <span>нет</span> :
            <span style={{color: 'green'}}>
                {moment(val).format('DD.MM.YYYY HH:mm:ss')}
            </span>
}, {
    key: 'p_rounds',
    sortable: false,
    label: 'Задания',
    template: ({val, row}) =>
        <span>
            {val.map((r, n) => {
                const started = moment(r.started).format('DD.MM.YYYY HH:mm:ss');
                const completed = r.completed === null ?
                    "не закончил" :
                    moment(r.completed).format('DD.MM.YYYY HH:mm:ss');

                return <span key={n}
                             style={{display: 'inline-block', marginRight: '6px'}}
                             title={`${started} — ${completed}`}>
                    {r.completed === null ?
                        null :
                        <span style={{color: 'green'}}>✓</span>
                    }
                    {r.round.round_no}
                </span>;
            })}
        </span>
}];


class ParticipantGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected_rows: {}
        };

        this.game_controller = registry.get('game_form_controller');

        this.columns = columns(this);

        this.winner_list_dialog = React.createRef();
        this.participant_form_dialog = React.createRef();
        this.add_participants_dialog = React.createRef();
    }

    render() {
        const c = this;
        const { id: game_id } = c.props.match.params;
        const selectable_rounds = c.game_controller.getModel('round_mode').getModelValue() === 'SELECTABLE';
        const game_by_invitation = c.game_controller.getModel('access_mode').getModelValue() !== 'PUBLIC';

        return <Grid controller={controller}
                     columns={c.columns}
                     title="Участники"
                     enableSelection={selectable_rounds}
                     isRowSelected={row => row.is_winner}
                     isRowDisabled={row => row.completed === null}
                     onRowCheckboxChanged={(row, event, checked) => controller.setWinner(row, checked)}
                     onHeaderCheckboxChanged={() => null}>

                { game_by_invitation && 
                    <Button onClick={() => c.add_participants_dialog.current.open()}>
                        Добавить участников
                    </Button>
                }

                { selectable_rounds &&
                    <Button component="a" href={`/admin/game/${game_id}/print-lottery`}
                       target="newtab">
                        распечатать билеты
                    </Button>
                }

                { selectable_rounds &&
                    <Button onClick={() => c.winner_list_dialog.current.open()}>
                        Сохранить победителей
                    </Button>
                }

                <WinnerListDialog ref={c.winner_list_dialog} />
                <ParticipantFormDialog ref={c.participant_form_dialog} />
                <AddParticipantsDialog ref={c.add_participants_dialog} />
            </Grid>;
    }

    componentWillMount() {
        const { id: game_id } = this.props.match.params;

        controller.setServersideFilter('game_id', game_id);
        controller.load(); // TODO!
    }
}

export default ParticipantGrid;
