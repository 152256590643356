import React from 'react';

import Grid from 'react-mui-lib/grid-widgets/Grid';
import EditButton from "react-mui-lib/grid-widgets/EditButton";
import registry from 'react-mui-lib/registry';


const columns = [{
    key: 'id',
    template: ({val, row}) =>
        <div style={{whiteSpace: 'nowrap'}}>
            <EditButton to={`/admin/franchise/${val}`} />
        </div>
}, {
    key: 'name',
    label: 'Название'
}, {
    key: 'locality.region.name',
    label: 'Регион'
}, {
    key: 'locality.name',
    label: 'Нас. пункт'
}];


class FranchiseGrid extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('franchise_list_controller');
    }

    render() {
        const c = this;

        return <Grid controller={c.controller}
                     columns={columns}
                     title="Франшизы"/>;
    }

    componentWillMount() {
        const c = this;
        c.controller.loadIfNeeded();
    }
}

export default FranchiseGrid;
