import {
    Schema, MapSchema, ListSchema,
    StringSchema, DateTimeSchema,
    required
} from 'persistent-models';

import FormController from 'react-mui-lib/form/controller';
import registry from 'react-mui-lib/registry';

import FormDelegate from '../locallib/form-delegate';


const schema = new MapSchema({
    login: StringSchema({
        validate: function(api) {
            api.callValidator(required('Поле должно быть заполнено'));
        },
        trim: true  // TODO
    }),
    is_enabled: new Schema({
        initialValue: true
    }),
    roles: new ListSchema(new MapSchema({
        id: StringSchema()
    }), {
        validate: function(api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    name: StringSchema({
        validate: function(api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    comment: StringSchema({}),
    franchise_id: StringSchema({
        validate: function(api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    registered: DateTimeSchema({
        view_format: 'DD.MM.YYYY',
        submit: false // TODO ?
    }),
    last_login: DateTimeSchema({
        view_format: 'DD.MM.YYYY',
        submit: false
    }),
    last_activity: DateTimeSchema({
        view_format: 'DD.MM.YYYY',
        submit: false
    }),
    new_password: StringSchema({})
});


class Delegate extends FormDelegate {
    didSubmit(json_response) {
        registry.get('user_grid_controller').needsRefresh();
        super.didSubmit(json_response);
    }

    didDelete() {
        registry.get('user_grid_controller').needsRefresh();
        super.didDelete();
    }
}


export default new FormController({
    schema,
    entity_name: 'user',
    state_prefix: 'user_form',
    Delegate
});
