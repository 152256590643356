import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';

import Form from 'react-mui-lib/form-widgets/Form';
import TextField from 'react-mui-lib/form-widgets/TextField';
import Checkbox from 'react-mui-lib/form-widgets/Checkbox';
import Select from 'react-mui-lib/form-widgets/Select';
import ImageField from 'react-mui-lib/form-widgets/ImageUploadField';
import RadioList from 'react-mui-lib/form-widgets/RadioList';
import registry from 'react-mui-lib/registry';

import NotificationDialog from './NotificationDialog';
import DatepickerField from '../locallib/DatepickerField';
import HelpButton from '../locallib/HelpButton';
import FieldWithHelp from '../locallib/FieldWithHelp';


const styles = {
    hidden: {
        display: 'none'
    },
    conditional_field_block: {
        borderLeft: '1px solid #666',
        paddingLeft: '24px'
    },
    last_round_start_end_dates: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    last_round_end_date: {
        display: 'inline-flex',
        flexDirection: 'column'
    },
    last_round_end_date_title: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '0.75rem',
        margin: '0 0 1em 0',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    last_round_end_date_value: {
        margin: '0'
    }
};


class GameForm extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('game_form_controller');
        this.franchise_list_controller = registry.get('franchise_list_controller');
    }

    render() {
        const c = this;
        const { classes } = c.props;  // injected by withStyles()

        const game_type_options = [{
            val: 'GAME',
            label: <span>Игра <HelpButton id="game_type_options.game" /></span>
        }, {
            val: 'TOUR',
            label: <span>Экскурсия <HelpButton id="game_type_options.tour" /></span>
        }];

        const access_mode_options = [{
            val: 'PUBLIC',
            label: <span>Открытая <HelpButton id="access_mode.public" /></span>
        }, {
            val: 'BY_INVITATION',
            label: <span>По приглашению <HelpButton id="access_mode.by_invitation" /></span>
        }, {
            val: 'PRIVATE',
            label: <span>Закрытая <HelpButton id="access_mode.private" /></span>
        }];

        const round_mode_options = [{
            val: 'SEQUENCE',
            label: <span>По порядку <HelpButton id="round_mode.public" /></span>
        }, {
            val: 'RANDOM',
            label: <span>В случайном порядке <HelpButton id="round_mode.random" /></span>
        }, {
            val: 'SELECTABLE',
            label: <span>По выбору <HelpButton id="round_mode.selectable" /></span>
        }];

        return <div>
            <Form controller={c.controller}
                  title_new="Новая игра"
                  title_edit="Игра"
                  actions_edit={[
                      <Button key="0"
                              onClick={() => c.refs.notification_dialog.open()}>
                          Отправить сообщения
                      </Button>
                  ]}>

                <FieldWithHelp>
                    <Checkbox path="is_published"
                              label="Опубликована" />
                    <HelpButton id="is_published" />
                </FieldWithHelp>

                <FieldWithHelp show={!c.controller.getModel('franchise_id').is('hidden')}>
                    <Select path="franchise_id"
                            options={c.franchise_list_controller.getAsOptions()}
                            label="Франшиза"/>
                </FieldWithHelp>

                <div>
                    <RadioList path="game_type"
                              label="Тип"
                              options={game_type_options} />
                </div>

                <FieldWithHelp>
                    <TextField path="name"
                               label="Название игры (не более 30 символов)" />
                    <HelpButton id="name" />
                </FieldWithHelp>
                <FieldWithHelp>
                    <TextField path="description"
                               label="Описание"
                               multiLine={true} />
                    <HelpButton id="description" />
                </FieldWithHelp>
                <FieldWithHelp>
                    <ImageField path="description_image"
                                config={{
                                    category: 'game-images',
                                    large_variant: '',
                                    enable_delete: false
                                }}
                                label="Изображение" />
                    <HelpButton id="description_image" />
                </FieldWithHelp>
                <FieldWithHelp>
                    <TextField path="location"
                               label="Место проведения" />
                    <HelpButton id="location" />
                </FieldWithHelp>
                <div className={classNames({
                    [classes.hidden]: c.controller.getModel('game_type').getModelValue() === 'TOUR'
                })}>
                    <FieldWithHelp>
                        <TextField path="prize"
                                label="Подарок" />
                        <HelpButton id="prize" />
                    </FieldWithHelp>
                </div>
                <FieldWithHelp>
                    <DatepickerField path="start_date"
                                     label="Дата и время начала" />
                    &nbsp;&nbsp;&nbsp;
                    <DatepickerField path="end_date"
                                     label="Дата и время окончания" />
                    <HelpButton id="start-end-dates" />
                </FieldWithHelp>
                <div>
                    <RadioList path="access_mode"
                              label="Доступ"
                              options={access_mode_options} />
                </div>
                <div>
                    <RadioList path="round_mode"
                              label="Задания"
                              options={round_mode_options} />
                </div>
                <div className={classNames({
                    [classes.hidden]:
                        c.controller.getModel('round_mode').getModelValue() === '' || 
                        c.controller.getModel('game_type').getModelValue() === 'TOUR',
                    [classes.conditional_field_block]: true
                })}>
                    <div className={classNames({
                        [classes.hidden]: c.controller.getModel('round_mode').getModelValue() === 'SELECTABLE'
                    })}>
                        <TextField path="n_winners"
                                   label="Количество победителей"
                                   width="medium" />
                            <HelpButton id="n_winners" />
                    </div>
                    {/* last round start / end date */}
                    <div className={classNames({
                        [classes.hidden]: c.controller.getModel('round_mode').getModelValue() !== 'SELECTABLE'
                    })}>
                        <FormLabel component="legend">
                            Последнее задание
                        </FormLabel>
                        <div className={classes.last_round_start_end_dates}>
                            <DatepickerField path="last_round_start_date"
                                            label="Дата и время начала" />
                            &nbsp;&nbsp;&nbsp;
                            <div className={classes.last_round_end_date}>
                                <p className={classes.last_round_end_date_title}>
                                    Дата и время окончания
                                </p>
                                <p className={classes.last_round_end_date_value}>
                                    {c.controller.getModel('end_date').getViewValue().format('DD.MM.YYYY HH:mm')}
                                </p>
                            </div>
                            <HelpButton id="last-round-start-end-dates" />
                        </div>
                    </div>
                    <FieldWithHelp show={!c.controller.getModel('round_message').is('hidden')}>
                        <TextField path="round_message"
                                label="Сообщение в списке заданий"
                                multiLine={true} />
                        <HelpButton id="round_message" />
                    </FieldWithHelp>
                    <FieldWithHelp show={!c.controller.getModel('interim_message').is('hidden')}>
                        <TextField path="interim_message"
                                label="Сообщение до выбора победителей"
                                multiLine={true} />
                        <HelpButton id="interim_message" />
                    </FieldWithHelp>
                </div> {/* /conditional_field_block */}
                <FieldWithHelp>
                    <TextField path="finished_message"
                               label="Сообщение по окончании игры"
                               multiLine={true} />
                    <HelpButton id="finished_message" />
                </FieldWithHelp>
                <div className={classNames({
                    [classes.hidden]: c.controller.getModel('game_type').getModelValue() === 'TOUR'
                })}>
                    <FieldWithHelp>
                        <TextField path="winner_message"
                                label="Сообщение для победителей"
                                multiLine={true} />
                        <HelpButton id="winner_message" />
                    </FieldWithHelp>
                </div>
            </Form>

            <NotificationDialog ref="notification_dialog" />
        </div>;
    }

    componentWillMount() {
        const c = this;
        const entity_id = c.props.match.params.id;

        if (entity_id === undefined) {
            c.controller.editNew();
        }
    }
}

export default connect(
    /* mapStateToProps */ state => ({
        state__: state
    }),
    /* mapDispatchToProps */ state => ({
    })
)(withStyles(styles)(GameForm));
