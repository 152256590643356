import React from 'react';

import Grid from 'react-mui-lib/grid-widgets/Grid';
import EditButton from "react-mui-lib/grid-widgets/EditButton";
import Select from 'react-mui-lib/grid-widgets/filters/Select';

import registry from 'react-mui-lib/registry';
import { has_superaccess } from "../utils";


const columns = [{
    key: 'id',
    template: ({val, row}) =>
        <div style={{whiteSpace: 'nowrap'}}>
            { has_superaccess() &&
                <EditButton to={`/admin/beacon/${val}`} />
            }
        </div>
}, {
    key: 'ibeacon_uuid',
    label: 'iBeacon UUID'
}, {
    key: 'ibeacon_major',
    label: 'iBeacon Major'
}, {
    key: 'ibeacon_minor',
    label: 'iBeacon Minor'
}, {
    key: 'franchise.name',
    label: 'Франшиза',
    include_if: has_superaccess()
}];


class BeaconGrid extends React.Component {
    constructor(props) {
        super(props);
        const c = this;
        c.controller = registry.get('beacon_list_controller');
    }

    render() {
        const c = this;

        return <Grid controller={c.controller}
                     columns={columns}
                     title="Маяки"/>;
    }

    componentWillMount() {
        const c = this;
        c.controller.loadIfNeeded();
    }

}

export default BeaconGrid;
