"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.normalizePath = normalizePath;
exports.getSchemaNodeByPath = getSchemaNodeByPath;
exports.getDataNodeByPath = getDataNodeByPath;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function normalizePath(path) {
  var path_split;

  if (_lodash.default.isArray(path)) {
    path_split = (0, _lodash.default)(path).map(function (el) {
      return typeof el === 'string' ? el.split('.') : el;
    }).flatten();
  } else {
    path_split = (0, _lodash.default)(path.split('.'));
  }

  path_split = path_split.filter(function (el) {
    return el !== '';
  }).map(function (el) {
    var el_as_int = parseInt(el, 10);
    return _lodash.default.isNaN(el_as_int) ? el : el_as_int;
  });
  return path_split.value();
}
/**
 * path: array of components
 */


function getSchemaNodeByPath(schema, path) {
  var schema_node = _lodash.default.reduce(path, function (node, comp) {
    return node.getChild(comp);
  }, schema); // TODO better error handling


  if (_lodash.default.isUndefined(schema_node)) {
    throw new TypeError("path does not exist in schema: ".concat(JSON.stringify(path)));
  }

  return schema_node;
}

function getDataNodeByPath(data, path) {
  if (path.length === 0) {
    return data;
  }

  var node = _lodash.default.get(data, path);

  if (_lodash.default.isUndefined(node)) {
    throw new TypeError("bad path \"".concat(path.join('.'), "\""));
  }

  return node;
}