import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import Grid from 'react-mui-lib/grid-widgets/Grid';
import EditButton from "react-mui-lib/grid-widgets/EditButton";
import Select from 'react-mui-lib/grid-widgets/filters/Select';
import registry from "react-mui-lib/registry";

import { format_timestamp } from '../utils';
import { has_superaccess } from "../utils";


const columns = [{
    key: 'id',
    template: ({val, row}) =>
        <div style={{whiteSpace: 'nowrap'}}>
            <EditButton to={`/admin/promo/${val}`} />
        </div>
}, {
    key: 'is_published',
    label: 'Опубликована',
    template: ({val}) => val ?
        <Tooltip title="Да">
            <span style={{color: 'green'}}>✔</span>
        </Tooltip> :
        <Tooltip title="Нет">
            <span style={{color: 'red'}}>✘</span>
        </Tooltip>
}, {
    key: 'title',
    label: 'Название'
}, {
    key: 'start_date',
    label: 'Начало',
    template: ({val, row}) =>
        <span>{format_timestamp(val)}</span>
}, {
    key: 'end_date',
    label: 'Окончание',
    template: ({val, row}) =>
        <span>{format_timestamp(val)}</span>
}, {
    key: 'franchise.name',
    label: 'Франшиза',
    include_if: has_superaccess()
}];


class PromoGrid extends React.Component {
    constructor(props) {
        super(props);
        const c = this;

        c.controller = registry.get('promo_grid_controller');
        c.franchise_list_controller = registry.get('franchise_list_controller');
    }

    render() {
        const c = this;

        return <Grid controller={c.controller}
                     columns={columns}
                     title="Акции">
            { has_superaccess() &&
                <Select controller={c.controller}
                        name="franchise_id"
                        label="- Франшиза -"
                        options={c.franchise_list_controller.getAsOptions()}/>
            }
        </Grid>;
    }

    componentWillMount() {
        const c = this;
        c.controller.loadIfNeeded();
    }
}

export default PromoGrid;
