import _ from 'lodash';

import registry from 'react-mui-lib/registry';

import ClientsideGridController from 'react-mui-lib/grid-clientside/controller';
import ClientsideGridReducer from 'react-mui-lib/grid-clientside/reducer';


class Reducer extends ClientsideGridReducer {
    INITIAL() {
        return Object.assign(super.INITIAL(), {
            by_id: {}
        });
    }

    _process(state) {
        const s = super._process(state);

        const by_id = {};
        s.rows.forEach(row => by_id[row.app_user_id] = row);

        return Object.assign(s, {
            by_id
        });
    }
}

class Controller extends ClientsideGridController {
    deleteFor(app_user_id) {
        const game_form_controller = registry.get('game_form_controller');
        const message_controller = registry.get('message_controller');
        const rest_client = registry.get('rest_client');

        rest_client.deleteById(
            this.name,
            `${app_user_id},${game_form_controller.state.entity_id}`
        ).then(json => {
            message_controller.showInfo('Участник удален');
            this.load();  // TODO ?
        }, error => {
            message_controller.showException('Ошибка удаления', error);
        });
    }

    //
    // add/remove participants (for games by invitation)
    //

    isParticipant(user_id) {
        return this.state.by_id.hasOwnProperty(user_id);
    }

    saveParticipants(ids_to_add, ids_to_remove) {
        const game_form_controller = registry.get('game_form_controller');
        const message_controller = registry.get('message_controller');
        const rest_client = registry.get('rest_client');

        return rest_client.post(
            `game/${game_form_controller.state.entity_id}/participants`, {
                add: ids_to_add,
                remove: ids_to_remove
            }
        ).then(data => {
            message_controller.showInfo('Участники сохранены');
        }, error => {
            message_controller.showException('Ошибка сохранения участников', error);
            throw error;
        });
    }

    //
    // winners for user selectable games
    //

    getWinners(rows) {
        const rows_to_use = rows === undefined ? this.state.rows : rows;

        return rows_to_use.filter(row =>
            row.is_winner
        ).sort((a, b) => a.winner_order - b.winner_order);
    }

    countWinners() {
        return this.state.rows.reduce(
            (cnt, row) => row.is_winner ? cnt + 1 : cnt,
            0
        );
    }

    setWinner(row, is_winner) {
        this.updateState(state => {
            const idx = state.rows.indexOf(row);

            if (idx === -1) {
                console.error('ERROR: row not found in state!');
                return state;
            }

            const new_rows = [...state.rows];

            new_rows[idx] = Object.assign({}, row, {
                is_winner: is_winner,
                winner_order: is_winner ? this.countWinners() + 1 : 0
            });

            // recalculate orders sequentually

            if (!is_winner) {
                const winners = this.getWinners(new_rows);  // returns winners in order

                for (let i = 0; i < winners.length; ++i) {
                    const winner = winners[i];
                    const idx = state.rows.indexOf(winner);

                    if (idx === -1) {
                        console.error('ERROR: row not found in state!');
                        continue;
                    }

                    new_rows[idx]  = Object.assign({}, winner, {
                        winner_order: i + 1
                    });
                }
            }

            return _.assign({}, state, {
                rows: new_rows
            });
        });
    }

    moveWinnerUp(row) {
        this.updateState(state => {
            row.winner_order -= 1;

            for (let i = 0; i < state.rows.length; ++i) {
                const r = state.rows[i];

                if (!r.is_winner) continue;

                if (r.winner_order === row.winner_order) {
                    r.winner_order += 1;
                    break;
                }
            }

            return state;
        });
    }

    moveWinnerDown(row) {
        this.updateState(state => {
            row.winner_order += 1;

            for (let i = 0; i < state.rows.length; ++i) {
                const r = state.rows[i];

                if (!r.is_winner) continue;

                if (r.winner_order === row.winner_order) {
                    r.winner_order -= 1;
                    break;
                }
            }

            return state;
        });
    }

    saveWinners() {
        const game_form_controller = registry.get('game_form_controller');
        const message_controller = registry.get('message_controller');
        const rest_client = registry.get('rest_client');

        const winners = this.state.rows.filter(row =>
            row.is_winner
        ).map(row => (
            {
                id: row.app_user_id,
                order: row.winner_order
            }
        ));

        return rest_client.post(
            `game/${game_form_controller.state.entity_id}/winners`, {
                winners
            }
        ).then(data => {
            message_controller.showInfo('Победители сохранены');
        }, error => {
            message_controller.showException('Ошибка сохранения победителей', error);
            throw error;
        });
    }
}


export default new Controller({
    entity_name: 'participant',
    state_prefix: 'participant_grid',
    Reducer,
    rows_per_page: 15,
    default_order_col: 'completed'
});
