import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { parse_image_id, image_url } from 'react-mui-lib/form-widgets/ImageUploadField/utils';


const styles = {
    image: {
        display: 'block',
        width: '100%'
    }
}

class Image extends React.Component {
    render() {
        const c = this;
        const { image_id, width, classes } = c.props;

        return <img src={c.getImageURL(image_id, width)}
                    className={classes.image} />;
    }

    getImageURL(image_id, width) {
        const c = this;

        let parsed_id;
        try {
            parsed_id = parse_image_id(image_id);
        } catch {
            console.log('getImageURL: error in parse_image_id()', image_id);
            return null;
        }

        return image_url(parsed_id, '/store', `W=${width}`);
    }
}

export default withStyles(styles)(Image);
