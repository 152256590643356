import moment from 'moment';

import {
    Schema, MapSchema, StringSchema,
    NumberSchema,
    required, length, range
} from 'persistent-models';
import FormController from 'react-mui-lib/form/controller';
import registry from 'react-mui-lib/registry';

import MomentSchema from '../locallib/moment-schema';
import FormDelegate from '../locallib/form-delegate';
import { has_superaccess } from '../utils';


const schema = new MapSchema({
    franchise_id: StringSchema({
        validate: function () {
            this.setFlag('hidden', !has_superaccess());
            this.setFlag('submit', has_superaccess());
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    is_published: new Schema({
        initialValue: true
    }),
    name: StringSchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
            this.callValidator(length(0, 30, 'Название не должно быть длиннее 30 символов'));
        }
    }),
    description: StringSchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    description_image: StringSchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    location: StringSchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    prize: StringSchema({
        validate: function () {
            const enable = this.forPath('game_type').getModelValue() !== 'TOUR';

            this.setFlag('hidden', !enable);
            this.setFlag('submit', enable);

            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    start_date: new MomentSchema({
        initialValue: moment(),
        parser_message: 'Введите дату и время, например, 20.08.2017 12:00',
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    end_date: new MomentSchema({
        initialValue: moment(),
        parser_message: 'Введите дату и время, например, 20.08.2017 12:00',
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    interim_message: StringSchema({
        validate: function () {
            const enable =
                this.forPath('round_mode').getModelValue() === 'SELECTABLE' &&
                this.forPath('game_type').getModelValue() !== 'TOUR';

            this.setFlag('hidden', !enable);
            this.setFlag('submit', enable);

            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    finished_message: StringSchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    winner_message: StringSchema({
        validate: function () {
            const enable = this.forPath('game_type').getModelValue() !== 'TOUR';

            this.setFlag('hidden', !enable);
            this.setFlag('submit', enable);

            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    game_type: StringSchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    access_mode: StringSchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    round_mode: StringSchema({
        validate: function () {
            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    n_winners: NumberSchema({
        initialValue: 5,
        validate: function () {
            const is_selectable = this.forPath('round_mode').getModelValue() === 'SELECTABLE';

            this.setFlag('hidden', is_selectable);
            this.setFlag('submit', !is_selectable);

            this.callValidator(required('Поле должно быть заполнено'));
            this.callValidator(range(1, 10, 'Должно быть от 1 до 10 победителей'));
        }
    }),
    last_round_start_date: new MomentSchema({
        initialValue: moment(),
        parser_message: 'Введите дату и время, например, 20.08.2017 12:00',
        validate: function () {
            const is_selectable = this.forPath('round_mode').getModelValue() === 'SELECTABLE';

            this.setFlag('hidden', !is_selectable);
            this.setFlag('submit', is_selectable);

            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    round_message: StringSchema({
        validate: function () {
            const enable =
                this.forPath('round_mode').getModelValue() === 'SELECTABLE' &&
                this.forPath('game_type').getModelValue() !== 'TOUR';

            this.setFlag('hidden', !enable);
            this.setFlag('submit', enable);

            this.callValidator(required('Поле должно быть заполнено'));
        }
    }),
});


class Delegate extends FormDelegate {
    didSubmit(json_response) {
        registry.get('game_grid_controller').needsRefresh();
        super.didSubmit(json_response);
    }
}


export default new FormController({
    schema,
    entity_name: 'game',
    state_prefix: 'game_form',
    Delegate
});
