import moment from 'moment';

import {
    Schema, MapSchema, ListSchema,
    StringSchema, NumberSchema, DateTimeSchema,
    required, length, range
} from 'persistent-models';
import FormController from 'react-mui-lib/form/controller';
import registry from 'react-mui-lib/registry';

import FormDelegate from '../../locallib/form-delegate';
import { has_superaccess } from '../../utils';


const schema = new MapSchema({
    app_user_id: StringSchema(),
    game_id:     StringSchema(),
    joined:      DateTimeSchema({
        view_format: 'DD.MM.YYYY HH:mm'
    }),
    completed:   DateTimeSchema({
        view_format: 'DD.MM.YYYY HH:mm'
    }),
    p_rounds: new ListSchema(new MapSchema({
        round_no: NumberSchema(),
        actual_round_no: NumberSchema(),
        started: DateTimeSchema({
            view_format: 'DD.MM.YYYY HH:mm'
        }),
        completed: DateTimeSchema({
            view_format: 'DD.MM.YYYY HH:mm'
        })
    }))
});


class Controller extends FormController {
    simulateCompleteRound() {
        const rest_client = registry.get('rest_client');
        const message_controller = registry.get('message_controller');

        return rest_client.post(
            `participant/${this.state.entity_id}/simulate_complete_round`
        ).then(data => {
            message_controller.showInfo('Задание выполнено');
        }, error => {
            message_controller.showException('Ошибка', error);
        });
    }
}


class Delegate extends FormDelegate {
    didSubmit(json_response) {
        registry.get('participant_grid_controller').needsRefresh();
        super.didSubmit(json_response);
    }
}


export default new Controller({
    schema,
    entity_name: 'participant',
    state_prefix: 'participant_form',
    Delegate
});
