import GridController from 'react-mui-lib/grid/controller';
import registry from "react-mui-lib/registry";


class Controller extends GridController {
    deleteById(item_id) {
        const message_controller = registry.get('message_controller');

        registry.get('rest_client').deleteById(
            this.name,
            item_id
        ).then(json => {
            message_controller.showInfo(`Пользователь ${item_id} удален`);
            this._request();
        }, error => {
            message_controller.showException('Ошибка удаления', error);
        });
    }
}


export default new Controller({
    entity_name: 'appuser',
    state_prefix: 'appuser_grid'
});
