import React from "react";
import cx from "classnames";

import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import registry from 'react-mui-lib/registry';

import GameDetailPreview from './GameDetailPreview';
import RoundPreview from './RoundPreview';

import phone_png from './phone.png';


const phone_width = 452;
const phone_height = 900;

const styles = {
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    root: {
        transform: 'scale(1.0)',  // changes dynamically
        position: 'fixed',
        height: `${phone_height}px`,  // image size
        width: `${phone_width}px`,
        top: `calc(50% - ${phone_height}px/2)`,
        left: `calc(50% - ${phone_width}px/2)`,
        backgroundImage: `url(${phone_png})`,
        backgroundRepeat: 'no-repeat',
        //backgroundPosition: 'center center',
        backgroundPosition: 'calc(50% - 1px) calc(50% - 0px)',
        outline: 'none'
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '394px',
        height: '694px',
        transform: 'translate(-50%, -50%)',
        overflowX: 'hidden',
        overflowY: 'auto',
        background: 'white',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    btn: {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        width: '64px',
        height: '64px',
        color: 'black',
        background: 'rgba(180, 180, 180, 0.75)',
        '&:hover': {
            background: 'rgba(200, 200, 200, 1)'
        }
    },
    btnLeft: {
        left: '-64px'
    },
    btnRight: {
        right: '-64px'
    },
    btnIcon: {
        fontSize: '3em',
        margin: '9px 0 0 9px'
    }
};

class PreviewPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_open: false,
            what: 'detail',
            round: 'description',
            round_no: 0
        }

        this.round_selector_controller = registry.get('round_selector_controller');

        this.rootRef = React.createRef();
    }

    open() {
        this.setState({
            is_open: true
        });
    }

    render() {
        const c = this;
        const { classes } = c.props;
        const { what, round, round_no } = c.state;

        return <Modal open={c.state.is_open}
                      BackdropProps={{classes: {root: classes.backdrop}}}
                      onRendered={() => c.updateDimensions()}
                      onClose={() => c.setState({is_open: false})}>
            <div ref={c.rootRef}
                 className={classes.root}>

                <div onClick={c.onPrev.bind(c)}
                        className={cx(classes.btn, classes.btnLeft)}>
                        <ChevronLeft className={classes.btnIcon} />
                </div>
                <div onClick={c.onNext.bind(c)}
                        className={cx(classes.btn, classes.btnRight)}>
                        <ChevronRight className={classes.btnIcon} />
                </div>

                <div className={classes.content}>
                    {what === 'detail' &&
                        <GameDetailPreview />
                    }
                    {what === 'round' &&
                        <RoundPreview round_no={round_no} screen={round} />
                    }
                </div>
            </div>
        </Modal>;
    }

    onNext() {
        const c = this;
        const { what, round, round_no } = c.state;
        const rsc = c.round_selector_controller;

        if (what === 'detail') {
            if (rsc.nRounds() > 0) {
                c.setState({
                    what: 'round',
                    round: 'description',
                    round_no: 0,
                });
            }
        } else if (what === 'round' && round === 'description') {
            if (rsc.hasCode(round_no)) {
                c.setState({
                    round: 'code-entry'
                });
            } else if (rsc.nRounds() > round_no + 1) {
                c.setState({
                    round: 'description',
                    round_no: round_no + 1
                });
            }
        } else if (what === 'round' && round === 'code-entry') {
            if (rsc.nRounds() > round_no + 1) {
                c.setState({
                    round: 'description',
                    round_no: round_no + 1
                });
            }
        }
    }

    onPrev() {
        const c = this;
        const { what, round, round_no } = c.state;
        const rsc = c.round_selector_controller;

        if (what === 'round' && round === 'code-entry') {
            c.setState({
                round: 'description'
            });
        } else if (what === 'round' && round === 'description') {
            if (round_no > 0) {
                c.setState({
                    round: rsc.hasCode(round_no - 1) ? 'code-entry' : 'description',
                    round_no: round_no - 1
                });
            } else {
                c.setState({
                    what: 'detail'
                });
            }
        }
    }

    updateDimensions() {
        const scale_x = (window.innerWidth - 100) / phone_width;
        const scale_y = (window.innerHeight - 70) / phone_height;
        const scale = Math.min(scale_x, scale_y, 1.0);

        const root = this.rootRef.current;
        if (root) {
            root.style.transform = `scale(${scale})`;
        }
    }

    componentDidMount() {
        this.updateDimensions = this.updateDimensions.bind(this);
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }    
}

export default withStyles(styles)(PreviewPopup);
