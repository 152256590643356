import React from 'react';
import { Router } from 'react-router'
import { Provider } from 'react-redux';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import registry from "react-mui-lib/registry";
import history from '../misc/history';
import App from './App';


function Root(props) {
    const params = Object.assign({
        install_type: 'development'
    }, window.Params);

    const install_type = params.install_type;

    const colors = {
        production: blue,
        staging: red,
        development: green
    };

    const theme = createMuiTheme({
        palette: {
            primary: colors[install_type]
        },
    });

    const titles = {
        production: 'Cryptofox Admin',
        staging: 'Cryptofox Admin — Staging',
        development: 'Cryptofox Admin — Development'
    };

    return <Provider store={registry.get('store')}>
        <Router history={history}>
            <MuiThemeProvider theme={theme}>
                <App title={titles[install_type]}/>
            </MuiThemeProvider>
        </Router>
    </Provider>;
}

export default Root;
