import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import registry from 'react-mui-lib/registry';

import Image from './Image';
import ic_time from './ic_time.png';
import ic_place from './ic_place.png';
import ic_n_rounds from './ic_n_rounds.png';
import ic_prize from './ic_prize.png';
import ic_info from './ic_info.png';


const itemStyles = {
    item: {
        display: 'flex',
        margin: '6px 16px'
    },
    icon: {
        width: '48px',
        height: '48px',
        marginTop: '11px'
    },
    text: {
        marginLeft: '12px'
    }
};

const Item = withStyles(itemStyles)(function({image, children, classes}) {
    return <div className={classes.item}>
        <img src={image} className={classes.icon} />
        <p className={classes.text}>
            {children}
        </p>
    </div>;
});


const styles = {
    main: {

    },
    name: {
        margin: '0',
        padding: '6px 0',
        color: 'white',
        background: '#7EB1D2', //'#F05B22',
        textAlign: 'center',
        fontWeight: '400',
        fontSize: '1.0em',
        textTransform: 'uppercase'
    },
    buttonContainer: {
        textAlign: 'center'
    },
    button: {
        display: 'inline-block',
        margin: '0 auto',
        padding: '16px',
        background: '#7EB1D2',
        color: 'white',
        textTransform: 'uppercase'
    },
    organizer: {
        margin: '12px 16px 0 16px',
        fontSize: '0.8em',
        textAlign: 'center'
    }
};

class GameDetailPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: null
        };

        this.game_controller = registry.get('game_form_controller');
        this.round_selector_controller = registry.get('round_selector_controller');
        this.franchise_controller = registry.get('franchise_form_controller');

        this.mainRef = React.createRef();
    }

    render() {
        const c = this;
        const { classes } = c.props;

        const game = c.game_controller.getModel().getViewValue();
        const totalRounds = c.round_selector_controller.nRounds();
        const franchise = c.franchise_controller.getModel().getViewValue();

        return <div className={classes.main} ref={c.mainRef}>
            <Image image_id={game.description_image} width={c.state.width} />
            <h2 className={classes.name}>
                {game.name}
            </h2>

            <Item image={ic_time}>{c.formatDates()}</Item>
            <Item image={ic_place}>{game.location}</Item>
            <Item image={ic_n_rounds}>
                Заданий: {totalRounds}
                <br/>
                Победителей: {game.n_winners}
            </Item>
            <Item image={ic_prize}>{game.prize}</Item>
            <Item image={ic_info}>{game.description}</Item>

            <div className={classes.buttonContainer}>
                <p className={classes.button}>
                    Вступить
                </p>
            </div>

            <p className={classes.organizer}>
                Организатор: {franchise.organizer_name},
                <br/>
                ИНН {franchise.organizer_inn},
                телефон: {franchise.organizer_phone}
            </p>
        </div>;
    }

    formatDates() {
        const c = this;

        const dateFormat = "DD.MM.YYYY HH:mm";
        const dateOnlyFormat = "DD.MM.YYYY";
        const timeOnlyFormat = "HH:mm";

        const startDate = c.game_controller.getModel('start_date').getModelValue();
        const endDate = c.game_controller.getModel('end_date').getModelValue();

        const same_day = startDate.format(dateOnlyFormat) === endDate.format(dateOnlyFormat);

        const start = startDate.format(dateFormat);
        const end = endDate.format(same_day ? timeOnlyFormat : dateFormat);

        return `${start} — ${end}`;
    }

    componentDidMount() {
        const c = this;

        c.setState({width: c.mainRef.current.offsetWidth});
    }
}

export default withStyles(styles)(GameDetailPreview);
