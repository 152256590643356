import React from 'react';
import { connect } from 'react-redux';

import Form from 'react-mui-lib/form-widgets/Form';
import TextField from 'react-mui-lib/form-widgets/TextField';
import Select from 'react-mui-lib/form-widgets/Select';
import registry from "react-mui-lib/registry";

import DeleteButton from '../locallib/DeleteButton';


class FranchiseForm extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('franchise_form_controller');
        this.region_list_controller = registry.get('region_list_controller');
        this.locality_list_controller = registry.get('locality_list_controller');
    }

    render() {
        const c = this;
        const region_id = c.controller.getModel('region_id').getModelValue();
        //console.log('>>>>>>>>>>>>>> r opts', c.region_list_controller.getAsOptions());
        //console.log('>>>>>>>>>>>>>>', region_id, c.locality_list_controller.getAsOptionsForRegion(region_id));

        return <Form controller={c.controller}
                     title_new="Новая франшиза"
                     title_edit="Франшиза"
                     actions_edit={[
                        <span style={{flexGrow: 1}} />,
                        <DeleteButton dialogTitle="Удалить франшизу?" />
                    ]}>

            <div>
                <Select path="region_id"
                        options={c.region_list_controller.getAsOptions()}
                        label="Регион" />
            </div>
            <div>
                <Select path="locality_id"
                        options={c.locality_list_controller.getAsOptionsForRegion(region_id)}
                        label="Нас. пункт" />
            </div>
            <div>
                <TextField path="name"
                           label="Название" />
            </div>
            <div>
                <TextField path="description"
                           label="Описание"
                           multiLine={true} />
            </div>
            <div>
                <TextField path="organizer_name"
                            label="Организатор игры — наименование" />
            </div>
            <div>
                <TextField path="organizer_inn"
                            label="Организатор игры — ИНН" />
            </div>
            <div>
                <TextField path="organizer_phone"
                            label="Организатор игры — телефон (+7 ххх ххх хх хх)" />
            </div>
        </Form>;
    }

    init(props) {
        const c = this;
        const entity_id = props.match.params.id;

        if (entity_id === undefined) {
            c.controller.editNew();
        } else {
            c.controller.editExisting(entity_id);
        }
    }

    componentWillMount() {
        this.init(this.props);
    }

    componentWillReceiveProps(new_props) {
        const c = this;

        if (new_props.location.pathname !== c.props.location.pathname) {
            c.init(new_props);
        }
    }
}


export default connect(
    /* mapStateToProps */ state => ({
        state__: state
    }),
    /* mapDispatchToProps */ state => ({
    })
)(FranchiseForm);

//export default FranchiseForm;
