import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import EditButton from "react-mui-lib/grid-widgets/EditButton";
import Grid from 'react-mui-lib/grid-widgets/Grid';
import Search from 'react-mui-lib/grid-widgets/filters/Search';
import Select from 'react-mui-lib/grid-widgets/filters/Select';
import registry from 'react-mui-lib/registry';

import { format_timestamp, has_superaccess } from '../utils';


const columns = [{
    key: 'id',
    template: ({val, row}) =>
        <div style={{whiteSpace: 'nowrap'}}>
            <EditButton to={`/admin/game/${val}`} />
        </div>
}, {
    key: 'is_published',
    label: 'Опубликована',
    template: ({val}) => val ?
        <Tooltip title="Да">
            <span style={{color: 'green'}}>✔</span>
        </Tooltip> :
        <Tooltip title="Нет">
            <span style={{color: 'red'}}>✘</span>
        </Tooltip>
}, {
    key: 'name',
    label: 'Название'
}, {
    key: 'start_date',
    label: 'Дата начала',
    template: ({val, row}) =>
        <span>{format_timestamp(val)}</span>
}, {
    key: 'end_date',
    label: 'Дата окончания',
    template: ({val, row}) =>
        <span>{format_timestamp(val)}</span>
}, {
    key: 'franchise.name',
    label: 'Франшиза',
    include_if: has_superaccess()
}];


class GameGrid extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('game_grid_controller');
        this.franchise_list_controller = registry.get('franchise_list_controller');
    }

    render() {
        const c = this;

        return <Grid controller={c.controller}
                  columns={columns}
                  title="Игры">
            <Search controller={c.controller}/>
            { has_superaccess() &&
                <Select controller={c.controller}
                        name="franchise_id"
                        label="- Франшиза -"
                        options={c.franchise_list_controller.getAsOptions()}/>
            }
        </Grid>;
    }

    componentWillMount() {
        const c = this;
        c.controller.loadIfNeeded();
    }
}

export default GameGrid;
