import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import Icon from  '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import { Grid } from 'react-mui-lib/grid-widgets/Grid';
import Search from 'react-mui-lib/grid-widgets/filters/Search';

import registry from 'react-mui-lib/registry';


const columns = c => [{
    key: '',
    template: ({val, row}) => {
        const is_participant = c.isParticipant(row.id);
        const on_button_click = () =>
            is_participant ? c.removeParticipant(row.id) : c.addParticipant(row.id);

        return <div style={{whiteSpace: 'nowrap'}}>
            <Tooltip title={is_participant ? "Удалить участника" : "Добавить участника"}>
                <IconButton onClick={on_button_click}>
                    <Icon>
                        {is_participant ? 'remove' : 'add'}
                    </Icon>
                </IconButton>
            </Tooltip>
        </div>;
    }
}, {
    key: 'id',
    label: 'Телефон'
}, {
    key: 'nickname',
    label: 'Никнейм'
}];


export default class AddParticipantsDialog extends React.Component {
    open() {
        const c = this;

        c.setState({
            dialog_open: true
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            dialog_open: false,
            ids_to_add: {},
            ids_to_remove: {}
        };

        this.user_controller = registry.get('appuser_grid_controller');
        this.participant_controller = registry.get('participant_grid_controller');

        this.columns = columns(this);
    }

    render() {
        const c = this;

        return <Dialog fullWidth={true}
                       open={c.state.dialog_open}
                       onClose={() => c.setState({dialog_open: false})}>
            <DialogTitle>
                Добавить участников
                <Search controller={c.user_controller} />
            </DialogTitle>
            <DialogContent>
                <Grid controller={c.user_controller}
                      columns={c.columns}
                      minWidth={530} />
            </DialogContent>
            <DialogActions>
                <Button color="primary"
                        onClick={() => c.saveParticipants()} >
                    Сохранить
                </Button>
                <Button 
                        onClick={() => c.close()} >
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>;
    }

    isParticipant(id) {
        const c = this;

        if (c.participant_controller.isParticipant(id)) {
            return !c.state.ids_to_remove.hasOwnProperty(id);
        }

        return c.state.ids_to_add.hasOwnProperty(id);
    }

    addParticipant(id) {
        const c = this;

        if (c.state.ids_to_remove.hasOwnProperty(id)) {
            c.setState({
                ids_to_remove: _.omit(c.state.ids_to_remove, [id])
            });
        } else {
            c.setState({
                ids_to_add: Object.assign(c.state.ids_to_add, {[id]: true})
            });
        }
    }

    removeParticipant(id) {
        const c = this;

        if (c.state.ids_to_add.hasOwnProperty(id)) {
            c.setState({
                ids_to_add: _.omit(c.state.ids_to_add, [id])
            });
        } else {
            c.setState({
                ids_to_remove: Object.assign(c.state.ids_to_remove, {[id]: true})
            });
        }
    }

    saveParticipants() {
        const c = this;

        if (_.isEmpty(c.state.ids_to_add) && _.isEmpty(c.state.ids_to_remove)) {
            c.setState({
                dialog_open: false
            });

            return;
        }

        c.participant_controller.saveParticipants(
            Object.keys(c.state.ids_to_add),
            Object.keys(c.state.ids_to_remove)
        ).then(() => {
            c.close();
            c.participant_controller.load();
        });
    }

    close() {
        const c = this;

        c.setState({
            dialog_open: false,
            ids_to_add: {},
            ids_to_remove: {}    
        });
    }

    componentWillMount() {
        const c = this;
        c.user_controller.loadIfNeeded();
    }
}
