import { Schema, StringSchema, DateTimeSchema, MapSchema } from 'persistent-models';
import FormController from 'react-mui-lib/form/controller';

import Delegate from '../locallib/form-delegate';


class HasFcmTokenSchema extends Schema {
    constructor() {
        super({
            initialValue: false
        });
    }

    format(value) {
        return value ? 'есть' : 'нет';
    }
}


const schema = new MapSchema({
    id: StringSchema({}),
    nickname: StringSchema({}),
    status: StringSchema({}),
    registered: DateTimeSchema({
        view_format: 'DD.MM.YYYY'
    }),
    confirm_code: StringSchema({}),
    has_fcm_token: new HasFcmTokenSchema(),
    fcm_token_updated: DateTimeSchema({
        view_format: 'DD.MM.YYYY'
    }),
    device_info: StringSchema({})
});


export default new FormController({
    schema,
    entity_name: 'appuser',
    state_prefix: 'appuser_form',
    Delegate
});
