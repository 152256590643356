import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import Icon from  '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import withStyles from '@material-ui/core/styles/withStyles';

import registry from 'react-mui-lib/registry';


const styles = {
    th: {
        textAlign: 'left'
    }
};


class _RoundList extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('participant_form_controller');
    }

    render() {
        const c = this;

        const { rounds, classes } = c.props;

        if (rounds.length === 0) {
            return <p>Нет заданий</p>;
        }

        return <table style={{width: '100%'}}>
            <thead>
                <tr>
                    <th className={classes.th}>№</th>
                    <th className={classes.th}>№ задания</th>
                    <th className={classes.th}>Начал</th>
                    <th className={classes.th}>Выполнил</th>
                </tr>
            </thead>
            <tbody>
                {rounds.map((r, i) =>
                    <tr id={r.actual_round_no.value}>
                        <td>{r.actual_round_no.value}</td>
                        <td>{r.round_no.value}</td>
                        <td>{c.controller.getModel(`p_rounds.${i}.started`).getViewValue()}</td>
                        <td>{c.controller.getModel(`p_rounds.${i}.completed`).getViewValue()}</td>
                    </tr>
                )}
            </tbody>
        </table>;
    }
}

const RoundList = withStyles(styles)(_RoundList);


export default class ParticipantFormDialog extends React.Component {
    open(app_user_id) {
        const c = this;
        const game_id = c.game_form_controller.state.entity_id;

        c.controller.editExisting(`${app_user_id},${game_id}`);

        c.setState({
            dialog_open: true
        });
    }

    constructor(props) {
        super(props);

        this.controller = registry.get('participant_form_controller');
        this.game_form_controller = registry.get('game_form_controller');

        this.state = {
            dialog_open: false
        };
    }

    render() {
        const c = this;

        const rounds = c.controller.state.data.p_rounds;

        return <Dialog fullWidth={true}
                       open={c.state.dialog_open}
                       onClose={() => c.setState({dialog_open: false})}>
            <DialogTitle>
                Задания участника {c.controller.getModel('app_user_id').getViewValue()}
            </DialogTitle>
            <DialogContent>
                <RoundList rounds={rounds} />
            </DialogContent>
            <DialogActions>
                <Button variant="raised"
                        color="primary"
                        disabled={c.controller.getModel('completed').getModelValue()}
                        onClick={() => c.simulateCompleteRound()}>
                    Выполнить следующее задание
                </Button>
                <Button color="primary"
                        onClick={() => c.setState({dialog_open: false})} >
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>;
    }

    simulateCompleteRound() {
        const c = this;
        c.controller.simulateCompleteRound().then(() => {
            c.controller.editExisting(c.controller.state.entity_id);
        });
    }
}
