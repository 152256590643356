import _ from 'lodash';

import registry from 'react-mui-lib/registry';
import BaseController from 'react-mui-lib/controller/base-controller';
import BaseReducer from 'react-mui-lib/controller/base-reducer';


class RoundSelectorReducer extends BaseReducer {
    INITIAL() {
        return {
            rows: [],
            game_id: null
        };
    }

    LOAD(state, action) {
        return _.assign({}, state, {
            rows: action.data,
            game_id: action.game_id
        });
    }

    LOAD_FAILED(state, action) {
        return state;
    }
}


class RoundSelectorController extends BaseController {
    constructor(state_prefix) {
        super('round_selector', state_prefix, RoundSelectorReducer);
    }

    nRounds() {
        return this.state.rows.length;
    }

    round(n) {
        return this.state.rows[n];
    }

    hasCode(n) {
        const code_mode = this.state.rows[n].code_mode;

        return code_mode === 'SINGLE' ||
               code_mode === 'GENERATED';
    }

    loadForGameId(game_id) {
        this._request(game_id).then(json => {
            this.dispatch({
                type: 'LOAD',
                data: json.data,
                game_id
            });
        }, err => { /* swallow reject from catch() in _request() */ });
    }

    reload() {
        this._request(this.state.game_id).then(json => {
            this.dispatch({
                type: 'LOAD',
                data: json.data,
                game_id: this.state.game_id
            });
        }, err => { /* swallow reject from catch() in _request() */ });
    }

    _request(game_id) {
        return registry.get('rest_client').getList(
            'round',
            {
                order: {
                    col: 'round_no',
                    dir: 'asc'
                },
                filters: {game_id: game_id}
            }
        ).catch((error) => {
            this.dispatch({
                type: 'LOAD_FAILED',
                error
            });

            registry.get('message_controller').showException('Ошибка загрузки', error);

            return Promise.reject();
        });
    }
}

export default new RoundSelectorController('round_selector');
