import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from 'react-mui-lib/form-widgets/TextField';
import Checkbox from 'react-mui-lib/form-widgets/Checkbox';
import RadioList from 'react-mui-lib/form-widgets/RadioList';

import registry from 'react-mui-lib/registry';


export default class NotificationDialog extends React.Component {
    open() {
        const c = this;

        if (!c.state.editing) {
            c.controller.editNew({
                title: c.game_controller.getModel('name').getModelValue(),
                message: '',
                filter: 'PARTICIPANTS',
                sound: false
            });
        }

        c.setState({
            dialog_open: true,
            editing: true
        });
    }

    constructor(props) {
        super(props);

        this.controller = registry.get('game_notifications_form_controller');
        this.game_controller = registry.get('game_form_controller');

        this.state = {
            dialog_open: false,
            editing: false
        };
    }

    render() {
        const c = this;

        const filter_options = [
            {val: 'ALL', label: 'Всем'},
            {val: 'PARTICIPANTS', label: 'Участникам игры'},
            {val: 'EXCEPT-PARTICIPANTS', label: 'Всем, кроме участников игры'}
        ];

        return <Dialog fullWidth={true}
                       open={c.state.dialog_open}
                       onClose={() => c.setState({dialog_open: false})}>
            <DialogTitle>
                Отправить сообщения со ссылкой на игру
            </DialogTitle>
            <DialogContent>
                <TextField path="title"
                           label="Заголовок" />
                <TextField path="message"
                           multiLine={true}
                           label="Сообщение" />
                <RadioList path="filter"
                           label="Кому отправить"
                           options={filter_options} />
                <Checkbox path="play_sound"
                          label="звук" />
            </DialogContent>
            <DialogActions>
                <Button variant="raised"
                        color="primary"
                        onClick={c.onSubmit.bind(c)}>
                    Отправить
                </Button>
                <Button color="primary"
                        onClick={() => c.setState({dialog_open: false})} >
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>;
    }

    getChildContext() {
        const c = this;

        return {
            controller: c.controller
        };
    }

    onSubmit() {
        const c = this;

        if (!c.controller.sendMessages()) return;

        c.setState({
            dialog_open: false,
            editing: false
        });
    }
}

NotificationDialog.childContextTypes = {
    controller: PropTypes.object
};
