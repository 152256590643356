import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import registry from "react-mui-lib/registry";


class ConfirmDeleteDialog extends React.Component {
    open(item_id) {
        this.setState({
            open: true,
            item_id
        });
    }

    constructor(props) {
        super(props);

        this.controller = registry.get('appuser_grid_controller');

        this.state = {
            open: false,
            item_id: null
        };
    }

    render() {
        const c = this;

        return <Dialog open={c.state.open}
                       onClose={c.onClose}>
            <DialogTitle>
                {`Удалить пользователя ${c.state.item_id}?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={c.onClose} color="primary">
                    Отмена
                </Button>
                <Button onClick={c.onDelete}
                        color="secondary"
                        variant="raised"
                        autoFocus>
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onDelete = () => {
        this.controller.deleteById(this.state.item_id);
        this.onClose();
    };

    onClose = () => {
        this.setState({open: false});
    };
}

export default ConfirmDeleteDialog;
