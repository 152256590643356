import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

import Form from 'react-mui-lib/form-widgets/Form';
import TextField from 'react-mui-lib/form-widgets/TextField';
import Checkbox from 'react-mui-lib/form-widgets/Checkbox';
import Select from 'react-mui-lib/form-widgets/Select';
import ImageField from 'react-mui-lib/form-widgets/ImageUploadField';

import registry from "react-mui-lib/registry";
import NotificationDialog from '../locallib/NotificationDialog';
import DatepickerField from '../locallib/DatepickerField';
import DeleteButton from '../locallib/DeleteButton';


class PromoForm extends React.Component {
    constructor(props) {
        super(props);

        this.controller = registry.get('promo_form_controller');
        this.franchise_list_controller = registry.get('franchise_list_controller');

        this.state = {
            notification_dialog_open: false
        };
    }

    render() {
        const c = this;
        const {
            notification_dialog_open
        } = c.state;

        return <Form controller={c.controller}
                     title_new="Новая акция"
                     title_edit="Акция"
                     actions_edit={[
                         <Button key="0"
                                 onClick={() => c.setState({notification_dialog_open: true})}>
                             Отправить сообщения
                         </Button>,
                         <span style={{flexGrow: 1}} />,
                         <DeleteButton dialogTitle="Удалить акцию?" />
                     ]}>

            <div>
                <Select path="franchise_id"
                        options={c.franchise_list_controller.getAsOptions()}
                        label="Франшиза"/>
            </div>
            <div>
                <Checkbox path="is_published"
                          label="Опубликована" />
            </div>
            <div>
                <DatepickerField path="start_date"
                           label="Дата и время начала" />
            </div>
            <div>
                <DatepickerField path="end_date"
                           label="Дата и время окончания" />
            </div>
            <div>
                <TextField path="title"
                           label="Название" />
            </div>
            <div>
                <TextField path="content"
                           label="Описание"
                           multiLine={true} />
            </div>
            <div>
                <ImageField path="image"
                            config={{
                                category: 'promo-images',
                                large_variant: '',
                                enable_delete: false
                            }}
                            label="Изображение" />
            </div>
            <div>
                <TextField path="link"
                           label="Ссылка" />
            </div>

            <NotificationDialog dialogTitle="Отправить сообщения"
                                open={notification_dialog_open}
                                title={c.controller.getModel('title').getModelValue()}
                                message={c.controller.getModel('content').getModelValue()}
                                onSubmit={c.onSendMessages.bind(c)}
                                onClose={() => c.setState({notification_dialog_open: false})} />
        </Form>;
    }

    onSendMessages(message) {
        const c = this;

        c.controller.sendMessages(message);
        c.setState({
            notification_dialog_open: false
        });
    }

    init(props) {
        const c = this;
        const entity_id = props.match.params.id;

        if (entity_id === undefined) {
            c.controller.editNew();
        } else {
            c.controller.editExisting(entity_id);
        }
    }

    componentWillMount() {
        this.init(this.props);
    }

    componentWillReceiveProps(new_props) {
        const c = this;

        if (new_props.location.pathname !== c.props.location.pathname) {
            c.init(new_props);
        }
    }
}

export default connect(
    /* mapStateToProps */ state => ({
        state__: state
    }),
    /* mapDispatchToProps */ state => ({
    })
)(PromoForm);
