import './public-path';  // must be imported first

import moment from 'moment';
import 'moment/locale/ru';

import React from 'react';
import ReactDOM from 'react-dom';

import './registry';
import create_store from './misc/store';
import { load_options_before_render, load_options } from './misc/options';
import Root from './app';
import registerServiceWorker from './registerServiceWorker';
import './index.css';


function render_react_app(root_component, root_el_id) {
    const root_el = document.getElementById(root_el_id);

    if (!root_el) {
        const msg = `Error: root element div#${root_el_id} does not exist`;
        console.error(msg);
        alert(msg);
        return;
    }

    ReactDOM.render(root_component, root_el);
}


function init() {
    moment.locale('ru');

    create_store();

    // TODO display error or reload app if options failed to load
    load_options_before_render().then(() =>
        render_react_app(<Root />, 'root'));

    load_options();

    if (process.env.NODE_ENV === 'production') {
        registerServiceWorker();
    }
}

init();
