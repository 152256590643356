"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StringSchema = StringSchema;
exports.NumberSchema = NumberSchema;
exports.DateTimeSchema = DateTimeSchema;
exports.PhoneSchema = PhoneSchema;
exports.MapSchema = MapSchema;
exports.ListSchema = ListSchema;
exports.getModel = getModel;
Object.defineProperty(exports, "Schema", {
  enumerable: true,
  get: function get() {
    return _schema.default;
  }
});
Object.defineProperty(exports, "Invalid", {
  enumerable: true,
  get: function get() {
    return _invalid.default;
  }
});
Object.defineProperty(exports, "required", {
  enumerable: true,
  get: function get() {
    return _validators.required;
  }
});
Object.defineProperty(exports, "range", {
  enumerable: true,
  get: function get() {
    return _validators.range;
  }
});
Object.defineProperty(exports, "length", {
  enumerable: true,
  get: function get() {
    return _validators.length;
  }
});
Object.defineProperty(exports, "regex", {
  enumerable: true,
  get: function get() {
    return _validators.regex;
  }
});
Object.defineProperty(exports, "uuid", {
  enumerable: true,
  get: function get() {
    return _validators.uuid;
  }
});
Object.defineProperty(exports, "email", {
  enumerable: true,
  get: function get() {
    return _validators.email;
  }
});

var _model = _interopRequireDefault(require("./model"));

var _schema = _interopRequireDefault(require("./schema"));

var _string = _interopRequireDefault(require("./schema/string"));

var _number = _interopRequireDefault(require("./schema/number"));

var _datetime = _interopRequireDefault(require("./schema/datetime"));

var _phone = _interopRequireDefault(require("./schema/phone"));

var _map = _interopRequireDefault(require("./schema/map"));

var _list = _interopRequireDefault(require("./schema/list"));

var _invalid = _interopRequireDefault(require("./invalid"));

var _validators = require("./validators");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function StringSchema(options) {
  return new _string.default(options);
}

function NumberSchema(options) {
  return new _number.default(options);
}

function DateTimeSchema(options) {
  return new _datetime.default(options);
}

function PhoneSchema(options) {
  return new _phone.default(options);
}

function MapSchema(options) {
  return new _map.default(options);
}

function ListSchema(options) {
  return new _list.default(options);
}

function getModel(schema) {
  return function (data) {
    var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    return new _model.default(schema, data, path);
  };
}