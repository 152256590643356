import _ from 'lodash';
import { createStore } from 'redux';

import registry from 'react-mui-lib/registry';


function create_store() {
    const controllers = registry
        .filter((el, name) => name.endsWith('_controller'))
        .map((el, name) => el);


    const combineControllers = (state = {}, action) => {
        return _.reduce(
            _.map(controllers, c => c.reducer.reduce(state, action)),
            (acc, el) => _.assign(acc, el),
            {}
        );
    };

    if (process.env.NODE_ENV !== 'production') {
        const prefixes = controllers
            .map(controller => controller.state_prefix)
            .sort();

        for (let i = 0; i < prefixes.length - 1; i++) {
            if (prefixes[i] === prefixes[i+1]) {
                throw new Error(`Duplicate state prefix: ${prefixes[i]}`);
            }
        }
    }

    const store = createStore(combineControllers);
    registry.register('store', store);

    // dispatch initial event
    // store.dispatch({
    //     type: 'INITIAL',
    //     state_prefix: '*'
    // });

    _.each(controllers, c => c.dispatch({
        type: 'INITIAL'
    }));

    if (process.env.NODE_ENV !== 'production') {
        window.gs = function () {
            return store.getState();
        };
    }
}


export default create_store;
