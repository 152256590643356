import registry from 'react-mui-lib/registry';
import { StringSchema, NumberSchema, MapSchema, required, uuid } from 'persistent-models';
import FormController from 'react-mui-lib/form/controller';

import FormDelegate from '../locallib/form-delegate';


// TODO initial view value is important: null makes components uncontrolled!
const schema = new MapSchema({
    franchise_id: StringSchema({
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    ibeacon_uuid: StringSchema({
        initialValue: '3D291B7F-7AAD-4199-8DD9-A88DF227E8FD',
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
            api.callValidator(uuid('Неправильный формат UUID'));
        }
    }),
    ibeacon_major: NumberSchema({
        format: 'hex',
        parser_message: 'Введите число в шестнадцатеричном формате, например, 01AB',
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    ibeacon_minor: NumberSchema({
        format: 'hex',
        parser_message: 'Введите число в шестнадцатеричном формате, например, 01AB',
        validate: function (api) {
            api.callValidator(required('Поле должно быть заполнено'));
        }
    }),
    description: StringSchema()
});


class Delegate extends FormDelegate {
    didSubmit(json_response) {
        registry.get('beacon_list_controller').needsRefresh();
        super.didSubmit(json_response);
    }

    didDelete() {
        registry.get('beacon_list_controller').needsRefresh();
        super.didDelete();
    }
}


export default new FormController({
    schema,
    entity_name: 'beacon',
    state_prefix: 'beacon_form',
    Delegate
});
