import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import registry from 'react-mui-lib/registry';

import Image from './Image';


const styles = {
    main: {

    },
    title: {
        margin: '0',
        padding: '6px 0',
        color: 'white',
        background: '#7EB1D2',
        textAlign: 'center',
        fontWeight: '400',
        fontSize: '1.0em',
        textTransform: 'uppercase'
    },
    radar: {
        display: 'block',
        margin: '0 auto'
    },
    description: {
        margin: '12px 16px 0 16px',
        textAlign: 'center'
    },
    codeInputContainer: {
        textAlign: 'center',
    },
    codeInput: {
        display: 'inline-block',
        width: '120px',
        height: '24px',
        borderBottom: '2px solid #7EB1D2'
    },
    buttonContainer: {
        textAlign: 'center'
    },
    button: {
        display: 'inline-block',
        margin: '0 auto',
        padding: '16px',
        background: '#7EB1D2',
        color: 'white',
        textTransform: 'uppercase'
    }
};

const Description = withStyles(styles)(function({round, classes}) {
    return <div>
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 100 100"
             width="250px" height="250px"
             className={classes.radar}>
                <circle cx="50" cy="50" r="45" stroke="#F05B22" strokeWidth="6" fill="none" />
                <circle cx="50" cy="50" r="35" stroke="#F05B22" strokeWidth="6" fill="none" />
                <circle cx="50" cy="50" r="25" stroke="#F05B22" strokeWidth="6" fill="none" />
                <circle cx="50" cy="50" r="15" stroke="#F05B22" strokeWidth="6" fill="none" />
                <circle cx="50" cy="50" r="5"  stroke="#F05B22" strokeWidth="6" fill="#F05B22" />
        </svg>

        <p className={classes.description}>
            {round.description}
        </p>
    </div>;
});

const CodeEntry = withStyles(styles)(function({round, classes}) {
    return <div>
        <p className={classes.description}>
            {round.code_message}
        </p>

        <p className={classes.codeInputContainer}>
            <span className={classes.codeInput}/>
        </p>

        <div className={classes.buttonContainer}>
            <p className={classes.button}>
                Продолжить
            </p>
        </div>
    </div>;
});

class RoundPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: null
        };

        this.game_controller = registry.get('game_form_controller');
        this.round_controller = registry.get('round_form_controller');
        this.round_selector_controller = registry.get('round_selector_controller');

        this.mainRef = React.createRef();
    }

    render() {
        const c = this;
        const { round_no, screen, classes } = c.props;

        const round = c.round_selector_controller.round(round_no);
        const totalRounds = c.round_selector_controller.nRounds();

        return <div className={classes.main}
                    ref={c.mainRef}>
            <Image image_id={round.description_image}
                   width={c.state.width} />

            <p className={classes.title}>
                Задание {round_no + 1} из {totalRounds}
            </p>

            {screen === 'description' &&
                <Description round={round} />
            }
            {screen === 'code-entry' &&
                <CodeEntry round={round} />
            }
        </div>;
    }

    componentDidMount() {
        const c = this;

        c.setState({width: c.mainRef.current.offsetWidth});
    }
}

export default withStyles(styles)(RoundPreview);
