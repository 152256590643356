import ListController from "react-mui-lib/list/controller";
import ClientsideGridController from "react-mui-lib/grid-clientside/controller";
import registry from "react-mui-lib/registry";

import "./admin-help-controller";
import { has_superaccess } from "../utils";


class BeaconListController extends ClientsideGridController {
    getAsOptions() {
        return this.state.rows.map(beacon => ({
            val: beacon.id,
            label: `${beacon.description} (${beacon.ibeacon_major}, ${beacon.ibeacon_minor})`
        }));
    }
}

registry.register('beacon_list_controller', new BeaconListController({
    entity_name: 'beacon',
    state_prefix: 'beacon_list',
    order_col: 'description'
}));


class RegionListController extends ListController {
    getAsOptions() {
        return this.state.rows.map(region => ({
            val: region.id.toString(),
            label: region.name
        }));
    }
}

registry.register('region_list_controller', new RegionListController({
    entity_name: 'region',
    state_prefix: 'region_list',
    order_col: 'name'
}));


class LocalityListController extends ListController {
    getAsOptionsForRegion(region_id) {
        const rows = this.state.rows.filter(locality =>
            locality.region_id === region_id);

        return rows.map(locality => ({
            val: locality.id,
            label: locality.name
        }));
    }
}

registry.register('locality_list_controller', new LocalityListController({
    entity_name: 'locality',
    state_prefix: 'locality_list',
    order_col: 'name'
}));


class FranchiseListController extends ClientsideGridController {
    getAsOptions() {
        const res = this.state.rows.map(franchise => ({
            val: franchise.id,
            label: franchise.name
        }));

        res.sort((a, b) => a.label.localeCompare(b.label));

        return res;
    }
}

registry.register('franchise_list_controller', new FranchiseListController({
    entity_name: 'franchise',
    state_prefix: 'franchise_list',
    order_col: 'name'
}));


class RoleListController extends ListController {
    getAsOptions() {
        return this.state.rows.map(role => ({
            val: role.id,
            label: `${role.name} (${role.description})`
        }));
    }
}

registry.register('role_list_controller', new RoleListController({
    entity_name: 'role',
    state_prefix: 'role_list',
    order_col: 'name'
}));


export function load_options_before_render() {
    let promises = [
        registry.get('beacon_list_controller').load()
    ];

    if (has_superaccess()) {
        promises = promises.concat([
            registry.get('region_list_controller').load(),
            registry.get('locality_list_controller').load(),
            registry.get('franchise_list_controller').load(),
            registry.get('role_list_controller').load()
        ]);
    }

    return Promise.all(promises);
}


export function load_options() {
    registry.get('admin_help_controller').load();
}
